import { createContext, useState } from 'react';
import { Client, defaultClient } from '../model/Client';
import { Employee, defaultEmployee } from '../model/Employee';
import { OperationDetail } from '../model/OperationDetail';
import { FinancialElement } from '../model/FinancialElement';
import { Goal } from '../model/Goal';

type ConfirmDiaglog = {
    callback: () => Promise<any>
    title: string
    subtitle?: string | React.JSX.Element
    cancelButton: string
    confirmButton: string
}

type Feedback = {
    id?: string
    message: string
    level: string
}

type ContextType = {
    userId: string
    operationDetails: OperationDetail[]
    setOperationDetails: React.Dispatch<React.SetStateAction<OperationDetail[]>>
    goals: Goal[]
    setGoals: React.Dispatch<React.SetStateAction<Goal[]>>
    financialElements: FinancialElement[]
    setFinancialElements: React.Dispatch<React.SetStateAction<FinancialElement[]>>
    client: Client
    setClient: React.Dispatch<React.SetStateAction<Client>>
    employee: Employee
    setEmployee: React.Dispatch<React.SetStateAction<Client>>
    feedbacks: Feedback[]
    addFeedback: (feedback: Feedback) => void
    removeFeedback: (feedback: Feedback) => void
    confirmDialog?: ConfirmDiaglog
    setConfirmDialog: React.Dispatch<React.SetStateAction<ConfirmDiaglog | undefined>>
}

const defaultContextType = {
    userId: '',
    operationDetails: [],
    setOperationDetails: () => { },
    goals: [],
    setGoals: () => { },
    financialElements: [],
    setFinancialElements: () => { },
    client: { ...defaultClient },
    setClient: () => { },
    employee: { ...defaultEmployee },
    setEmployee: () => { },
    feedbacks: [],
    addFeedback: () => { },
    removeFeedback: () => { },
    setConfirmDialog: () => { },
}

export const Context = createContext<ContextType>(defaultContextType);

export function ContextProvider(props: any) {
    const [operationDetails, setOperationDetails] = useState<OperationDetail[]>([]);
    const [goals, setGoals] = useState<Goal[]>([]);
    const [financialElements, setFinancialElements] = useState<FinancialElement[]>([]);
    const [client, setClient] = useState<Client>({ ...defaultClient });
    const [employee, setEmployee] = useState<Employee>({ ...defaultEmployee });
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
    const [confirmDialog, setConfirmDialog] = useState<ConfirmDiaglog>();

    const addFeedback = (feedback: Feedback) => setFeedbacks(oldFeedbacks => {
        const feedbackWithId = {
            ...feedback,
            id: (new Date()).getTime().toString()
        };
        setTimeout(() => removeFeedback(feedbackWithId), 5000);

        return [...oldFeedbacks, feedbackWithId];
    });

    const removeFeedback = (feedback: Feedback) => setFeedbacks(oldFeedbacks => oldFeedbacks.filter(oldFeedback => oldFeedback.id !== feedback.id));

    return (
        <Context.Provider value={{
            userId: props.userId,
            operationDetails,
            setOperationDetails,
            goals,
            setGoals,
            financialElements,
            setFinancialElements,
            client,
            setClient,
            employee,
            setEmployee,
            feedbacks,
            addFeedback,
            removeFeedback,
            confirmDialog,
            setConfirmDialog
        }}>
            {props.children}
        </ Context.Provider>
    )
}
