import { useContext } from "react";
import { Context } from "../../utils/context";

export default function ClientData(): JSX.Element {
    const { client } = useContext(Context);

    return (
        <article>
            <h2>Datos del cliente</h2>
            <div className="flex space-x-1">
                <h3>Nombre:</h3>
                <p>{client.name}</p>
            </div>
            <div className="flex space-x-1">
                <h3>Número de teléfono:</h3>
                <p>{client.phone_number}</p>
            </div>
            <div className="flex space-x-1">
                <h3>Descripción adicional:</h3>
                <p>{client.description}</p>
            </div>
        </article>
    );
}
