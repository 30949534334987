import { useContext, useEffect, useState } from "react";
import { listClientAppointments } from "../../utils/api";
import { Context } from "../../utils/context";

export default function ClientAppointment(): JSX.Element {
    const { client } = useContext(Context);
    const [clientAppointment, setClientAppointment] = useState<Date | null>(null);
    const [appointmentDuration, setAppointmentDuration] = useState<string | null>(null);

    useEffect(() => {
        async function setUp() {
            const result = await listClientAppointments(client.id ?? '');
            const timestamps = result['timestamps'];
            if (timestamps.length > 0) {
                setClientAppointment(new Date(parseInt(timestamps[0])));
            }
            const appointmentDuration = result['duration_minutes'];
            setAppointmentDuration(appointmentDuration);
        }
        setUp();
    }, [client.id]);

    return (
        <article>
            <h2>Reunión agendada</h2>
            {(clientAppointment && appointmentDuration) ? (<>
                <div className="flex space-x-1">
                    <h3>Fecha y hora para el coach:</h3>
                    <p>{clientAppointment.toLocaleString("es-ES", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZoneName: 'shortGeneric'
                    })}</p>
                </div>
                <div className="flex space-x-1">
                    <h3>Fecha y hora para el cliente:</h3>
                    <p>{clientAppointment.toLocaleString("es-ES", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZoneName: 'shortGeneric',
                        timeZone: client.timezone,
                    })}</p>
                </div>
                <div className="flex space-x-1">
                    <h3>Duración:</h3>
                    <p>{appointmentDuration} minutos</p>
                </div>
            </>) : (
                <p>No hay reunión agendada</p>
            )}
        </article>
    );
}
