import { useContext, useState } from "react";
import { createValuation, updateClient } from "../../utils/api";
import { Context } from "../../utils/context";
import { getHourInMillisecondsString, getMidnightTimestampString } from "../../utils/date-utils";

export default function LeaveInitialProfile(): JSX.Element {
    const { client, setClient, financialElements, setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(false);

    async function finalizeInitialProfile() {
        setLoading(true);
        try {
            for (let i = 0; i < financialElements.length; i++) {
                const financialElement = financialElements[i];
                await createValuation(client.id ?? '', {
                    financial_element_id: financialElement.id,
                    currency_code: financialElement.currency_code,
                    amount: financialElement.amount,
                    timestamp: getMidnightTimestampString(financialElement.update_timestamp),
                    hour: getHourInMillisecondsString(financialElement.update_timestamp)
                });
            }
            const updatedClient = {
                ...client,
                status: 'enabled',
                initial_profile_leave_timestamp: (new Date()).getTime().toString()
            };
            await updateClient(client.id ?? '', updatedClient);
            setClient(updatedClient);
        } finally {
            setLoading(false);
        }
    }

    const confirmFinalizeInitialProfileDialogParams = {
        callback: finalizeInitialProfile,
        title: '¿Estás seguro que deseas salir del modo Perfil inicial?',
        subtitle: 'Esta acción es irreversible.',
        confirmButton: 'Aceptar',
        cancelButton: 'Cancelar'
    };

    return (
        <div className='col-span-full flex justify-center'>
            <button className='button-secondary-red ring-1 ring-sf-red-dark' onClick={() => setConfirmDialog(confirmFinalizeInitialProfileDialogParams)} disabled={loading}>Salir del modo Perfil inicial</button>
        </div>
    );
}
