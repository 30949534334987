import { Currency } from "./Currency";

export interface Client {
    readonly 'currencies'?: Currency[];
    readonly 'id'?: string;
    readonly 'name'?: string;
    readonly 'status'?: string;
    readonly 'email'?: string;
    readonly 'enable_plan'?: boolean;
    readonly 'stress_level'?: string;
    readonly 'initial_profile_leave_timestamp'?: string;
    readonly 'description'?: string;
    readonly 'phone_number'?: string;
    readonly 'timezone'?: string;
    readonly 'identity_id'?: string;
    readonly 'cognito_status'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultClient: Client = {
    'currencies': [],
    'id': '',
    'name': '',
    'status': '',
    'email': '',
    'enable_plan': false,
    'stress_level': '',
    'initial_profile_leave_timestamp': '',
    'description': '',
    'phone_number': '',
    'timezone': '',
    'identity_id': '',
    'cognito_status': '',
    'creation_timestamp': '',
    'update_timestamp': ''
};
