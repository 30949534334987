function UnitedArabEmirates(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5957 128.25 64.2495C128.25 28.9033 99.5962 0.249512 64.25 0.249512C28.9038 0.249512 0.25 28.9033 0.25 64.2495C0.25 99.5957 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M36.4238 86.5103L41.9891 124.269C48.9221 126.842 56.4213 128.25 64.2498 128.25C91.7673 128.25 115.226 110.882 124.269 86.5103H36.4238Z' fill='black' />
      <path d='M36.4238 41.9888L41.9891 4.22976C48.9221 1.65701 56.4213 0.249512 64.2498 0.249512C91.7673 0.249512 115.226 17.6175 124.269 41.9888H36.4238Z' fill='#6DA544' />
      <path d='M0.25 64.2495C0.25 91.7672 17.6182 115.226 41.9893 124.269V4.23022C17.6182 13.273 0.25 36.7317 0.25 64.2495Z' fill='#A2001D' />
    </svg>
  );
}

function Argentina(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.2496 0.25C38.8761 0.25 16.9521 15.0163 6.6001 36.424H121.899C111.547 15.0163 89.6228 0.25 64.2496 0.25Z' fill='#338AF3' />
      <path d='M64.2496 128.25C89.6228 128.25 111.547 113.484 121.899 92.0759H6.6001C16.9521 113.484 38.8761 128.25 64.2496 128.25Z' fill='#338AF3' />
      <path d='M83.3788 64.25L75.5626 67.9267L79.7248 75.4965L71.2373 73.8727L70.1618 82.4467L64.2501 76.1407L58.3381 82.4467L57.2628 73.8727L48.7753 75.4962L52.9373 67.9265L45.1213 64.25L52.9376 60.5732L48.7753 53.0037L57.2626 54.6272L58.3383 46.0532L64.2501 52.3592L70.1621 46.0532L71.2373 54.6272L79.7251 53.0037L75.5628 60.5735L83.3788 64.25Z' fill='#FFDA44' />
    </svg>
  );
}

function Australia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.25 64.25C128.25 99.596 99.596 128.25 64.25 128.25C28.904 128.25 0.25 99.596 0.25 64.25C0.25 64.265 64.25 0.25725 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25Z' fill='#0052B4' />
      <path d='M64.25 0.25C64.2465 0.25 64.2428 0.25025 64.2393 0.25025L64.25 0.25Z' fill='#F0F0F0' />
      <path d='M64.0787 64.25H64.25C64.25 64.192 64.25 64.1365 64.25 64.0787C64.193 64.136 64.136 64.193 64.0787 64.25Z' fill='#F0F0F0' />
      <path d='M64.25 33.6415C64.25 22.3802 64.25 15.001 64.25 0.250244H64.2393C28.898 0.255994 0.25 28.9072 0.25 64.25H33.6413V45.447L52.4442 64.25H64.079C64.1362 64.193 64.1933 64.136 64.2503 64.0787C64.2503 59.767 64.2503 55.9197 64.2503 52.4447L45.447 33.6415H64.25Z' fill='#F0F0F0' />
      <path d='M32.6288 8.59778C22.619 14.2975 14.2975 22.619 8.59778 32.6288V64.25H25.2935V25.2938V25.2935H64.25C64.25 20.0278 64.25 15.0113 64.25 8.59778H32.6288Z' fill='#D80027' />
      <path d='M64.25 56.3798L41.5117 33.6418H33.6415C33.6415 33.6416 33.6415 33.6418 33.6415 33.6418L64.2497 64.2501H64.25C64.25 64.2501 64.25 58.8238 64.25 56.3798Z' fill='#D80027' />
      <path d='M38.8488 75.3805L42.3613 82.725L50.293 80.8917L46.741 88.2172L53.1195 93.2755L45.1778 95.0655L45.2 103.206L38.8488 98.1132L32.4978 103.206L32.52 95.0655L24.578 93.2755L30.9568 88.2172L27.4043 80.8917L35.3365 82.725L38.8488 75.3805Z' fill='#F0F0F0' />
      <path d='M96.071 89.2936L97.8273 92.9658L101.793 92.0491L100.017 95.7118L103.207 98.2411L99.2355 99.1361L99.2465 103.207L96.071 100.66L92.8955 103.207L92.9065 99.1361L88.9358 98.2411L92.125 95.7118L90.349 92.0491L94.3148 92.9658L96.071 89.2936Z' fill='#F0F0F0' />
      <path d='M79.7333 50.337L81.4893 54.0095L85.4553 53.0925L83.6793 56.7553L86.8685 59.2845L82.8978 60.1795L82.9085 64.25L79.7333 61.7033L76.5578 64.25L76.5685 60.1795L72.5978 59.2845L75.787 56.7553L74.011 53.0925L77.977 54.0095L79.7333 50.337Z' fill='#F0F0F0' />
      <path d='M96.071 28.076L97.8273 31.7486L101.793 30.8318L100.017 34.4945L103.206 37.0235L99.2355 37.9188L99.2465 41.9893L96.071 39.4426L92.8955 41.9893L92.9065 37.9188L88.9358 37.0235L92.1248 34.4945L90.349 30.8318L94.3148 31.7486L96.071 28.076Z' fill='#F0F0F0' />
      <path d='M110.342 44.7719L112.098 48.4443L116.064 47.5273L114.288 51.1901L117.477 53.7193L113.506 54.6146L113.517 58.6848L110.342 56.1383L107.167 58.6848L107.177 54.6146L103.207 53.7193L106.396 51.1901L104.62 47.5273L108.586 48.4443L110.342 44.7719Z' fill='#F0F0F0' />
      <path d='M100.138 64.25L101.519 68.5015H105.989L102.373 71.1292L103.754 75.3805L100.138 72.753L96.521 75.3805L97.9023 71.1292L94.2858 68.5015H98.756L100.138 64.25Z' fill='#F0F0F0' />
    </svg>
  );
}

function Bahrain(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.25 0.25C51.406 0.25 39.448 4.03625 29.425 10.5495L45.8847 21.5818L25.2935 32.2482L45.8847 42.9147L25.2935 53.5812L45.8847 64.2465L25.2935 74.9128L45.8847 85.579L25.2935 96.2465L45.8847 106.913L29.423 117.949C39.4465 124.463 51.4052 128.25 64.25 128.25C99.596 128.25 128.25 99.596 128.25 64.25C128.25 28.904 99.596 0.25 64.25 0.25V0.25Z' fill='#D80027' />
    </svg>
  );
}

function Bangladesh(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#496E2D' />
      <path d='M50.337 92.076C65.7049 92.076 78.163 79.6179 78.163 64.25C78.163 48.8821 65.7049 36.424 50.337 36.424C34.9691 36.424 22.511 48.8821 22.511 64.25C22.511 79.6179 34.9691 92.076 50.337 92.076Z' fill='#D80027' />
    </svg>
  );
}

function Bulgaria(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.248 64.25C128.248 56.4215 126.84 48.9222 124.267 41.9892L64.2476 39.2065L4.22831 41.989C1.65581 48.9222 0.247559 56.4215 0.247559 64.25C0.247559 72.0785 1.65581 79.5777 4.22831 86.5107L64.2476 89.2935L124.267 86.511C126.84 79.5777 128.248 72.0785 128.248 64.25Z' fill='#496E2D' />
      <path d='M64.2475 128.25C91.7653 128.25 115.224 110.882 124.267 86.5107H4.22827C13.271 110.882 36.7298 128.25 64.2475 128.25Z' fill='#D80027' />
      <path d='M4.22827 41.9893H124.267C115.224 17.618 91.7653 0.25 64.2475 0.25C36.7298 0.25 13.271 17.618 4.22827 41.9893Z' fill='#F0F0F0' />
    </svg>
  );
}

function Bolivia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.25 64.2505C128.25 56.422 126.842 48.9227 124.269 41.9897L64.25 39.207L4.23075 41.9895C1.658 48.9227 0.25 56.422 0.25 64.2505C0.25 72.079 1.658 79.5782 4.23075 86.5112L64.25 89.294L124.269 86.5115C126.842 79.5782 128.25 72.079 128.25 64.2505Z' fill='#FFDA44' />
      <path d='M64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5112H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25Z' fill='#6DA544' />
      <path d='M4.23071 41.9897H124.269C115.226 17.6185 91.7677 0.250488 64.25 0.250488C36.7322 0.250488 13.2735 17.6185 4.23071 41.9897Z' fill='#D80027' />
    </svg>
  );
}

function Brazil(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#6DA544' />
      <path d='M64.25 25.2935L117.119 64.25L64.25 103.207L11.3805 64.25L64.25 25.2935Z' fill='#FFDA44' />
      <path d='M64.25 86.5108C76.5443 86.5108 86.5108 76.5443 86.5108 64.25C86.5108 51.9557 76.5443 41.9893 64.25 41.9893C51.9557 41.9893 41.9893 51.9557 41.9893 64.25C41.9893 76.5443 51.9557 86.5108 64.25 86.5108Z' fill='#F0F0F0' />
      <path d='M53.1195 62.8588C49.2485 62.8588 45.5127 63.4475 41.9962 64.54C42.152 76.7 52.053 86.511 64.25 86.511C71.792 86.511 78.453 82.7568 82.4797 77.019C75.5905 68.3948 64.9902 62.8588 53.1195 62.8588Z' fill='#0052B4' />
      <path d='M86.0982 68.515C86.3662 67.134 86.511 65.7093 86.511 64.25C86.511 51.9555 76.5445 41.9893 64.2502 41.9893C55.0767 41.9893 47.2017 47.5395 43.7937 55.464C46.8062 54.8398 49.9252 54.511 53.1199 54.511C66.0492 54.5108 77.7457 59.8853 86.0982 68.515Z' fill='#0052B4' />
    </svg>
  );
}

function Belarus(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#FCFCFC' />
      <path d='M26.6846 59.612L19.7281 46.9912L26.6846 34.5685L33.6411 46.9912L26.6846 59.612Z' fill='#A2001D' />
      <path d='M12.7717 59.612L5.81519 46.9912L12.7717 34.5685L19.7282 46.9912L12.7717 59.612Z' fill='#A2001D' />
      <path d='M26.6846 93.931L19.7281 81.3103L26.6846 68.8875L33.6411 81.3103L26.6846 93.931Z' fill='#A2001D' />
      <path d='M12.7717 93.931L5.81519 81.3103L12.7717 68.8875L19.7282 81.3103L12.7717 93.931Z' fill='#A2001D' />
      <path d='M33.6412 12.6728L31.6717 9.15576C28.1392 11.2488 24.8259 13.672 21.7732 16.383L26.6847 25.2933L33.6412 12.6728Z' fill='#A2001D' />
      <path d='M26.6847 103.206L21.7212 112.07C24.7712 114.785 28.0822 117.212 31.6132 119.309L33.6414 115.629L26.6847 103.206Z' fill='#A2001D' />
      <path d='M39.2065 80.9457V123.161C46.8988 126.436 55.362 128.25 64.25 128.25C91.7678 128.25 115.227 110.882 124.269 86.5107L39.2065 80.9457Z' fill='#6DA544' />
      <path d='M124.269 86.5107C126.842 79.5775 128.25 72.0785 128.25 64.25C128.25 28.904 99.596 0.25 64.25 0.25C55.3628 0.25 46.8988 2.06325 39.2065 5.337V86.511H124.269V86.5107Z' fill='#A2001D' />
    </svg>
  );
}

function Canada(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M128.25 64.25C128.25 38.8765 113.484 16.9525 92.076 6.60052V121.899C113.484 111.548 128.25 89.6235 128.25 64.25Z' fill='#D80027' />
      <path d='M0.25 64.25C0.25 89.6235 15.0163 111.548 36.424 121.9V6.60052C15.0163 16.9525 0.25 38.8765 0.25 64.25Z' fill='#D80027' />
      <path d='M75.3805 72.5978L86.5108 67.0325L80.9458 64.25V58.6848L69.8153 64.25L75.3805 53.1195H69.8153L64.25 44.7718L58.6848 53.1195H53.1195L58.6848 64.25L47.5543 58.6848V64.25L41.9893 67.0325L53.1195 72.5978L50.337 78.163H61.4675V86.5108H67.0325V78.163H78.163L75.3805 72.5978Z' fill='#D80027' />
    </svg>
  );
}

function Switzerland(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.1445 128.25C99.4908 128.25 128.145 99.5962 128.145 64.25C128.145 28.9038 99.4908 0.25 64.1445 0.25C28.7983 0.25 0.144531 28.9038 0.144531 64.25C0.144531 99.5962 28.7983 128.25 64.1445 128.25Z' fill='#D80027' />
      <path d='M97.5359 53.1196H75.2752V30.8586H53.0142V53.1196H30.7534V75.3804H53.0142V97.6411H75.2752V75.3804H97.5359V53.1196Z' fill='#F0F0F0' />
    </svg>
  );
}

function Chile(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M128.25 64.25C128.25 99.596 99.596 128.25 64.25 128.25C28.904 128.25 0.25 99.596 0.25 64.25C0.25 28.904 64.25 64.25 64.25 64.25C64.25 64.25 112.69 64.25 128.25 64.25Z' fill='#D80027' />
      <path d='M0.25 64.25C0.25 28.904 28.904 0.25 64.25 0.25V64.25C64.25 64.25 22.5107 64.25 0.25 64.25Z' fill='#0052B4' />
      <path d='M38.3472 22.5107L42.4915 35.2652H55.9022L45.0527 43.1477L49.1967 55.9022L38.3472 48.0195L27.4977 55.9022L31.6417 43.1477L20.7922 35.2652H34.203L38.3472 22.5107Z' fill='#F0F0F0' />
    </svg>
  );
}

function China(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M35.275 39.2L40.8 56.2H58.675L44.225 66.725L49.75 83.725L35.275 73.225L20.8 83.725L26.35 66.725L11.875 56.2H29.75L35.275 39.2Z' fill='#FFDA44' />
      <path d='M76.125 99.375L71.9001 94.175L65.6501 96.6L69.2751 90.975L65.05 85.75L71.5251 87.475L75.175 81.85L75.5251 88.55L82.0251 90.275L75.75 92.675L76.125 99.375Z' fill='#FFDA44' />
      <path d='M84.525 84.125L86.525 77.725L81.05 73.85L87.75 73.75L89.725 67.35L91.9 73.7L98.6 73.625L93.225 77.625L95.375 83.975L89.9 80.1L84.525 84.125Z' fill='#FFDA44' />
      <path d='M95.85 47.2249L92.9 53.2499L97.7 57.9249L91.075 56.9749L88.125 62.9749L86.975 56.3749L80.325 55.4249L86.275 52.2999L85.125 45.6749L89.925 50.3499L95.85 47.2249Z' fill='#FFDA44' />
      <path d='M76.3 28.975L75.8 35.65L82.025 38.175L75.5 39.775L75.025 46.475L71.5 40.775L64.975 42.375L69.3 37.25L65.75 31.575L71.975 34.1L76.3 28.975Z' fill='#FFDA44' />
    </svg>
  );
}

function Colombia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.25 64.25C0.25 28.904 28.904 0.25 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25L64.25 69.8152L0.25 64.25Z' fill='#FFDA44' />
      <path d='M8.81396 96.2499C19.8802 115.379 40.5612 128.25 64.25 128.25C87.9387 128.25 108.62 115.379 119.686 96.2499L64.25 92.0759L8.81396 96.2499Z' fill='#D80027' />
      <path d='M119.686 96.25C125.132 86.8365 128.25 75.9078 128.25 64.25H0.25C0.25 75.9078 3.3685 86.8365 8.814 96.25H119.686Z' fill='#0052B4' />
    </svg>
  );
}

function CostaRica(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.5 128.25C99.8462 128.25 128.5 99.5962 128.5 64.25C128.5 28.9038 99.8462 0.25 64.5 0.25C29.1538 0.25 0.5 28.9038 0.5 64.25C0.5 99.5962 29.1538 128.25 64.5 128.25Z' fill='#F0F0F0' />
      <path d='M124.519 41.9893H4.48075C1.90825 48.9225 0.5 56.4215 0.5 64.25C0.5 72.0785 1.90825 79.5775 4.48075 86.5108H124.52C127.092 79.5775 128.5 72.0785 128.5 64.25C128.5 56.4215 127.092 48.9225 124.519 41.9893Z' fill='#D80027' />
      <path d='M64.5006 0.25C45.1029 0.25 27.7221 8.881 15.9854 22.5107H113.016C101.279 8.881 83.8984 0.25 64.5006 0.25Z' fill='#0052B4' />
      <path d='M113.016 105.989H15.9854C27.7221 119.619 45.1029 128.25 64.5006 128.25C83.8984 128.25 101.279 119.619 113.016 105.989Z' fill='#0052B4' />
    </svg>
  );
}

function CzechRepublic(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M58.6847 64.25C58.6847 64.25 19.0325 109.514 18.995 109.505C30.5765 121.087 46.5767 128.25 64.25 128.25C99.596 128.25 128.25 99.596 128.25 64.25H58.6847Z' fill='#D80027' />
      <path d='M18.995 18.995C-5.9985 43.9885 -5.9985 84.5112 18.995 109.505C29.3233 99.1767 39.2565 89.2435 64.25 64.25L18.995 18.995Z' fill='#0052B4' />
    </svg>
  );
}

function Algeria(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25C128.25 99.596 99.596 128.25 64.25 128.25C64.25 125.467 55.9022 64.25 55.9022 64.25L64.25 0.25Z' fill='#F0F0F0' />
      <path d='M64.25 128.25C28.904 128.25 0.25 99.596 0.25 64.25C0.25 28.904 28.904 0.25 64.25 0.25' fill='#496E2D' />
      <path d='M78.001 51.9738L72.75 59.21L64.2457 56.452L69.5052 63.682L64.2547 70.9183L72.7557 68.1505L78.0152 75.3803L78.0097 66.44L86.511 63.672L78.007 60.9145L78.001 51.9738Z' fill='#D80027' />
      <path d='M69.5595 82.3368C59.5705 82.3368 51.4725 74.2388 51.4725 64.2498C51.4725 54.2608 59.5705 46.1628 69.5595 46.1628C72.674 46.1628 75.6048 46.9503 78.1633 48.3368C74.1493 44.4115 68.6603 41.9888 62.603 41.9888C50.3085 41.9888 40.3423 51.9553 40.3423 64.2495C40.3423 76.5438 50.3088 86.5103 62.603 86.5103C68.6605 86.5103 74.1495 84.0875 78.1633 80.1623C75.6048 81.5493 72.674 82.3368 69.5595 82.3368Z' fill='#D80027' />
    </svg>
  );
}

function Denmark(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M50.337 55.9022H127.708C123.617 24.5005 96.7665 0.25 64.2498 0.25C59.471 0.25 54.8168 0.77825 50.3368 1.77125V55.9022H50.337Z' fill='#D80027' />
      <path d='M33.641 55.902V8.03174C16.0678 17.6202 3.48725 35.208 0.791504 55.9022H33.641V55.902Z' fill='#D80027' />
      <path d='M33.6408 72.5978H0.791504C3.48725 93.292 16.0678 110.88 33.641 120.468L33.6408 72.5978Z' fill='#D80027' />
      <path d='M50.3368 72.598V126.729C54.8168 127.722 59.471 128.25 64.2498 128.25C96.7665 128.25 123.617 104 127.708 72.5978H50.3368V72.598Z' fill='#D80027' />
    </svg>
  );
}

function Egypt(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.25 0.25C36.7322 0.25 13.2735 17.618 4.23071 41.9893H124.269C115.226 17.618 91.7677 0.25 64.25 0.25Z' fill='#D80027' />
      <path d='M64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5107H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25Z' fill='black' />
      <path d='M86.5108 57.2935H69.815C69.815 54.22 67.3233 51.7283 64.2498 51.7283C61.1763 51.7283 58.6845 54.22 58.6845 57.2935H41.9888C41.9888 60.3673 44.666 62.8588 47.7395 62.8588H47.554C47.554 65.9325 50.0455 68.424 53.1193 68.424C53.1193 71.4978 55.6108 73.9893 58.6845 73.9893H69.815C72.8888 73.9893 75.3803 71.4978 75.3803 68.424C78.454 68.424 80.9455 65.9325 80.9455 62.8588H80.7603C83.8338 62.8588 86.5108 60.3673 86.5108 57.2935Z' fill='#FF9811' />
    </svg>
  );
}

function EuropeanUnion(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#0052B4' />
      <path d='M64.2499 25.2935L66.3222 31.6707H73.0272L67.6024 35.612L69.6747 41.9892L64.2499 38.0477L58.8249 41.9892L60.8972 35.612L55.4724 31.6707H62.1777L64.2499 25.2935Z' fill='#FFDA44' />
      <path d='M36.7031 36.7035L42.6781 39.7475L47.4193 35.0065L46.3701 41.629L52.3448 44.6732L45.7221 45.7222L44.6731 52.3452L41.6288 46.3705L35.0063 47.4197L39.7476 42.6785L36.7031 36.7035Z' fill='#FFDA44' />
      <path d='M25.2935 64.2499L31.6707 62.1777V55.4724L35.6117 60.8974L41.9892 58.8252L38.0475 64.2499L41.9892 69.6747L35.6117 67.6027L31.6707 73.0274V66.3222L25.2935 64.2499Z' fill='#FFDA44' />
      <path d='M36.7031 91.7964L39.7473 85.8214L35.0063 81.0802L41.6291 82.1297L44.6728 76.1549L45.7221 82.7777L52.3446 83.8267L46.3706 86.8709L47.4193 93.4934L42.6781 88.7522L36.7031 91.7964Z' fill='#FFDA44' />
      <path d='M64.2499 103.206L62.1774 96.8292H55.4724L60.8974 92.888L58.8249 86.5112L64.2499 90.4522L69.6747 86.5112L67.6024 92.888L73.0272 96.8292H66.3219L64.2499 103.206Z' fill='#FFDA44' />
      <path d='M91.7965 91.7964L85.8218 88.7524L81.0803 93.4937L82.1295 86.8707L76.1553 83.8267L82.7778 82.7777L83.8268 76.1549L86.8708 82.1297L93.4933 81.0802L88.752 85.8219L91.7965 91.7964Z' fill='#FFDA44' />
      <path d='M103.206 64.2499L96.829 66.3222V73.0274L92.8877 67.6024L86.511 69.6747L90.4522 64.2499L86.511 58.8252L92.888 60.8974L96.829 55.4724V62.1779L103.206 64.2499Z' fill='#FFDA44' />
      <path d='M91.7965 36.7035L88.7523 42.6785L93.4935 47.4197L86.8705 46.3702L83.8268 52.345L82.7778 45.7222L76.1553 44.673L82.1295 41.629L81.0803 35.0067L85.822 39.7477L91.7965 36.7035Z' fill='#FFDA44' />
    </svg>
  );
}

function Fiji(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.25 64.25C128.25 99.596 99.596 128.25 64.25 128.25C28.904 128.25 0.25 99.596 0.25 64.25C0.25 64.2653 64.25 0.257 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25Z' fill='#338AF3' />
      <path d='M64.2504 30.8586V52.4444L41.9896 30.8586H64.2504Z' fill='#0052B4' />
      <path d='M33.6416 64.2499H52.4446L33.6416 39.2064L30.8591 58.6847L33.6416 64.2499Z' fill='#0052B4' />
      <path d='M64.0787 64.25H64.25C64.25 64.1925 64.25 64.1365 64.25 64.0787C64.193 64.136 64.136 64.193 64.0787 64.25Z' fill='#F0F0F0' />
      <path d='M64.25 33.6413C64.25 22.38 64.25 15.0013 64.25 0.25H64.2393C28.898 0.256 0.25 28.9072 0.25 64.25H33.6413V45.4473L52.4442 64.25H64.0793C64.1362 64.193 64.1933 64.136 64.2505 64.0788C64.2505 59.767 64.2505 55.9198 64.2505 52.4445L45.447 33.6413H64.25Z' fill='#F0F0F0' />
      <path d='M32.6288 8.59778C22.619 14.2975 14.2975 22.619 8.59778 32.6288V64.25H25.2935V25.294V25.2935H64.25C64.25 20.0278 64.25 15.0113 64.25 8.59778H32.6288Z' fill='#D80027' />
      <path d='M64.25 56.3799L41.5117 33.6416H33.6415V33.6421L64.2495 64.2501H64.25C64.25 64.2501 64.25 58.8234 64.25 56.3799Z' fill='#D80027' />
      <path d='M72.5978 41.9893V64.2498C72.5978 79.1598 92.076 83.7283 92.076 83.7283C92.076 83.7283 111.554 79.1598 111.554 64.2498V41.9893L92.076 36.4241L72.5978 41.9893Z' fill='#F3F3F3' />
      <path d='M111.554 41.9892V33.6415H72.5978V41.9892H89.293V58.6852H72.5978V64.25V64.2505L89.293 64.2507V82.875C90.9725 83.469 92.076 83.7285 92.076 83.7285C92.076 83.7285 93.179 83.4695 94.8583 82.875V64.2505H111.554V64.25V58.685H94.8583V41.989H111.554V41.9892Z' fill='#D80027' />
    </svg>
  );
}

function UnitedKingdom(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M13.4794 25.2854C8.4521 31.8262 4.66135 39.3649 2.4541 47.5546H35.7486L13.4794 25.2854Z' fill='#0052B4' />
      <path d='M126.044 47.5546C123.837 39.3651 120.046 31.8264 115.019 25.2856L92.7505 47.5546H126.044Z' fill='#0052B4' />
      <path d='M2.4541 80.9458C4.6616 89.1353 8.45235 96.6741 13.4794 103.215L35.7479 80.9458H2.4541Z' fill='#0052B4' />
      <path d='M103.214 13.4801C96.6731 8.45284 89.1346 4.66209 80.9448 2.45459V35.7488L103.214 13.4801Z' fill='#0052B4' />
      <path d='M25.2847 115.019C31.8254 120.047 39.3642 123.837 47.5537 126.045V92.751L25.2847 115.019Z' fill='#0052B4' />
      <path d='M47.5534 2.45459C39.3639 4.66209 31.8252 8.45284 25.2847 13.4798L47.5534 35.7486V2.45459Z' fill='#0052B4' />
      <path d='M80.9453 126.045C89.1348 123.837 96.6736 120.047 103.214 115.02L80.9453 92.751V126.045Z' fill='#0052B4' />
      <path d='M92.7505 80.9458L115.019 103.215C120.046 96.6743 123.837 89.1353 126.044 80.9458H92.7505Z' fill='#0052B4' />
      <path d='M127.708 55.9022H72.5983H72.598V0.79175C69.8652 0.436 67.0792 0.25 64.25 0.25C61.4202 0.25 58.6348 0.436 55.9022 0.79175V55.9017V55.902H0.79175C0.436 58.6348 0.25 61.4207 0.25 64.25C0.25 67.0798 0.436 69.8652 0.79175 72.5977H55.9017H55.902V127.708C58.6348 128.064 61.4202 128.25 64.25 128.25C67.0792 128.25 69.8652 128.064 72.5977 127.708V72.5983V72.598H127.708C128.064 69.8652 128.25 67.0798 128.25 64.25C128.25 61.4207 128.064 58.6348 127.708 55.9022Z' fill='#D80027' />
      <path d='M80.9458 80.9461L109.505 109.505C110.818 108.192 112.071 106.819 113.267 105.396L88.8163 80.9458H80.9458V80.9461Z' fill='#D80027' />
      <path d='M47.5544 80.946H47.5539L18.9951 109.505C20.3081 110.818 21.6809 112.071 23.1039 113.267L47.5544 88.8158V80.946Z' fill='#D80027' />
      <path d='M47.5544 47.5549V47.5544L18.9954 18.9951C17.6819 20.3081 16.4289 21.6809 15.2334 23.1039L39.6841 47.5546H47.5544V47.5549Z' fill='#D80027' />
      <path d='M80.9458 47.5546L109.505 18.9951C108.192 17.6816 106.819 16.4286 105.396 15.2334L80.9458 39.6841V47.5546Z' fill='#D80027' />
    </svg>
  );
}

function Georgia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M127.708 55.9022H72.5983H72.598V0.79175C69.8652 0.436 67.0792 0.25 64.25 0.25C61.4207 0.25 58.6348 0.436 55.9022 0.79175V55.9017V55.902H0.79175C0.436 58.6348 0.25 61.4207 0.25 64.25C0.25 67.0798 0.436 69.8652 0.79175 72.5977H55.9017H55.902V127.708C58.6348 128.064 61.4207 128.25 64.25 128.25C67.0792 128.25 69.8652 128.064 72.5977 127.708V72.5983V72.598H127.708C128.064 69.8652 128.25 67.0798 128.25 64.25C128.25 61.4207 128.064 58.6348 127.708 55.9022Z' fill='#D80027' />
      <path d='M39.2065 30.8587V22.5107H30.8587V30.8587H22.5107V39.2065H30.8587V47.5542H39.2065V39.2065H47.5542V30.8587H39.2065Z' fill='#D80027' />
      <path d='M97.6412 30.8587V22.5107H89.2935V30.8587H80.9457V39.2065H89.2935V47.5542H97.6412V39.2065H105.989V30.8587H97.6412Z' fill='#D80027' />
      <path d='M39.2065 89.2936V80.9458H30.8587V89.2936H22.5107V97.6413H30.8587V105.989H39.2065V97.6413H47.5542V89.2936H39.2065Z' fill='#D80027' />
      <path d='M97.6412 89.2936V80.9458H89.2935V89.2936H80.9457V97.6413H89.2935V105.989H97.6412V97.6413H105.989V89.2936H97.6412Z' fill='#D80027' />
    </svg>
  );
}

function Ghana(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.25 64.25C0.25 72.0785 1.65825 79.5775 4.23075 86.5108L64.25 89.2935L124.269 86.511C126.842 79.5775 128.25 72.0785 128.25 64.25C128.25 56.4215 126.842 48.9225 124.269 41.9893L64.25 39.2065L4.23075 41.989C1.65825 48.9225 0.25 56.4215 0.25 64.25H0.25Z' fill='#FFDA44' />
      <path d='M64.25 0.25C36.7322 0.25 13.2735 17.618 4.23071 41.9893H124.269C115.226 17.618 91.7677 0.25 64.25 0.25Z' fill='#D80027' />
      <path d='M124.269 86.5107H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5107Z' fill='#496E2D' />
      <path d='M64.2495 41.9893L69.7745 58.9943H87.6565L73.1908 69.505L78.7158 86.5108L64.2495 76.0008L49.7833 86.5108L55.3093 69.505L40.8435 58.9943H58.7245L64.2495 41.9893Z' fill='black' />
    </svg>
  );
}

function Guinea(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M86.5115 4.23149C79.578 1.65874 72.0788 0.250488 64.25 0.250488C56.4218 0.250488 48.9228 1.65874 41.9898 4.23099L39.207 64.2505L41.9895 124.27C48.9228 126.842 56.4218 128.25 64.25 128.25C72.079 128.25 79.578 126.842 86.5115 124.269L89.294 64.2505L86.5115 4.23149Z' fill='#FFDA44' />
      <path d='M0.25 64.2505C0.25 91.768 17.618 115.227 41.9893 124.269V4.23096C17.618 13.274 0.25 36.7325 0.25 64.2505Z' fill='#D80027' />
      <path d='M128.25 64.2505C128.25 36.7325 110.882 13.274 86.5107 4.23096V124.27C110.882 115.227 128.25 91.768 128.25 64.2505Z' fill='#6DA544' />
    </svg>
  );
}

function Guatemala(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M128.25 64.25C128.25 38.8765 113.484 16.9525 92.0759 6.60046V121.899C113.484 111.547 128.25 89.6232 128.25 64.25Z' fill='#338AF3' />
      <path d='M0.25 64.25C0.25 89.6232 15.0163 111.547 36.424 121.899V6.60046C15.0163 16.9525 0.25 38.8765 0.25 64.25Z' fill='#338AF3' />
      <path d='M80.9744 75.0721L70.1529 64.2506L80.4536 53.9493L79.9869 48.5088L77.0379 45.5593L64.2499 58.3473L51.4619 45.5593L48.5129 48.5088L48.0461 53.9493L58.3469 64.2506L47.5251 75.0721L53.4281 80.9746L64.2499 70.1533L75.0716 80.9746L80.9744 75.0721Z' fill='#ACABB1' />
      <path d='M79.9908 48.5093L74.088 54.412C76.6058 56.9298 78.1633 60.408 78.1633 64.25C78.1633 71.934 71.934 78.163 64.2503 78.163C56.5665 78.163 50.337 71.934 50.337 64.25C50.337 60.408 51.8945 56.9298 54.4123 54.412L48.5095 48.5093C44.4808 52.5373 41.9893 58.1025 41.9893 64.25C41.9893 76.5445 51.9558 86.5108 64.25 86.5108C76.5443 86.5108 86.5108 76.5443 86.5108 64.25C86.5108 58.1028 84.0193 52.5375 79.9908 48.5093Z' fill='#6DA544' />
    </svg>
  );
}

function HongKong(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M70.8579 48.6672C69.4034 54.7169 66.8191 53.5632 65.5509 58.8392C58.0801 57.0429 53.4791 49.5304 55.2754 42.0592C57.0709 34.5884 64.5841 29.9874 72.0551 31.7837C69.5184 42.3354 72.1591 43.2564 70.8579 48.6672Z' fill='#F0F0F0' />
      <path d='M51.4721 53.1502C56.7764 56.4027 54.8804 58.5044 59.5059 61.3412C55.4889 67.8914 46.9226 69.9452 40.3721 65.9284C33.8219 61.9117 31.7676 53.3449 35.7846 46.7947C45.0361 52.4677 46.7274 50.2409 51.4721 53.1502Z' fill='#F0F0F0' />
      <path d='M49.7449 72.9733C54.4776 68.9338 55.8899 71.3863 60.0171 67.8638C65.0059 73.708 64.3119 82.49 58.4679 87.4788C52.6234 92.4675 43.8414 91.7728 38.8534 85.929C47.1069 78.8833 45.5116 76.586 49.7449 72.9733Z' fill='#F0F0F0' />
      <path d='M68.0639 80.7413C65.6839 74.9921 68.4529 74.4061 66.3782 69.3928C73.4779 66.4546 81.6159 69.8281 84.5544 76.9278C87.4927 84.0276 84.1187 92.1653 77.0189 95.1038C72.8697 85.0766 70.1924 85.8838 68.0639 80.7413Z' fill='#F0F0F0' />
      <path d='M81.1129 65.7191C74.9097 66.2059 75.2074 63.3909 69.7987 63.8154C69.1979 56.1556 74.9214 49.4579 82.5817 48.8566C90.2417 48.2569 96.9392 53.9799 97.5397 61.6404C86.7204 62.4886 86.6607 65.2844 81.1129 65.7191Z' fill='#F0F0F0' />
    </svg>
  );
}

function Croatia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.25 64.2505C128.25 56.422 126.842 48.9228 124.269 41.9898L64.25 39.207L4.23075 41.9895C1.65825 48.9228 0.25 56.422 0.25 64.2505C0.25 72.079 1.65825 79.5783 4.23075 86.5113L64.25 89.294L124.269 86.5115C126.842 79.5783 128.25 72.079 128.25 64.2505Z' fill='#F0F0F0' />
      <path d='M64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5112H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25Z' fill='#0052B4' />
      <path d='M4.23071 41.9897H124.269C115.226 17.6185 91.7677 0.250488 64.25 0.250488C36.7322 0.250488 13.2735 17.6185 4.23071 41.9897Z' fill='#D80027' />
      <path d='M80.9457 44.7724H69.8152L71.6704 30.8592L79.0904 25.2939L86.5107 30.8592V41.9897L80.9457 44.7724Z' fill='#338AF3' />
      <path d='M47.5543 44.7724H58.6848L56.8295 30.8592L49.4093 25.2939L41.9893 30.8592V41.9897L47.5543 44.7724Z' fill='#338AF3' />
      <path d='M71.6706 44.7724H56.8296V30.8592L64.2501 25.2939L71.6706 30.8592V44.7724Z' fill='#0052B4' />
      <path d='M41.9893 41.99V72.5985C41.9893 79.8867 45.5098 86.3682 50.9398 90.4315L54.8355 89.4567L59.7515 94.4022C61.2053 94.7017 62.7095 94.8595 64.2503 94.8595C65.7828 94.8595 67.2795 94.7037 68.7255 94.4072L74.3373 89.7347L77.5603 90.4225C82.988 86.3595 86.511 79.8847 86.511 72.5985V41.99H41.9893Z' fill='#F0F0F0' />
      <path d='M41.9893 41.99H50.8935V50.8942H41.9893V41.99Z' fill='#D80027' />
      <path d='M59.7979 41.99H68.7021V50.8942H59.7979V41.99Z' fill='#D80027' />
      <path d='M77.6064 41.99H86.5107V50.8942H77.6064V41.99Z' fill='#D80027' />
      <path d='M50.8936 50.8943H59.7978V59.7985H50.8936V50.8943Z' fill='#D80027' />
      <path d='M68.7021 50.8943H77.6064V59.7985H68.7021V50.8943Z' fill='#D80027' />
      <path d='M41.9893 59.7958H50.8935V68.7H41.9893V59.7958Z' fill='#D80027' />
      <path d='M50.8936 68.7002H59.7978V77.6044H50.8936V68.7002Z' fill='#D80027' />
      <path d='M59.7979 59.7958H68.7021V68.7H59.7979V59.7958Z' fill='#D80027' />
      <path d='M77.6064 59.7958H86.5107V68.7H77.6064V59.7958Z' fill='#D80027' />
      <path d='M68.7021 68.7002H77.6064V77.6044H68.7021V68.7002Z' fill='#D80027' />
      <path d='M59.7979 77.6045H68.7021V86.5087H59.7979V77.6045Z' fill='#D80027' />
      <path d='M50.8935 77.6052H42.5637C43.3277 80.9152 44.8297 83.944 46.8892 86.5095H50.8935V77.6052Z' fill='#D80027' />
      <path d='M77.6064 86.5097H81.6104C83.6704 83.9442 85.1719 80.9152 85.9359 77.6055H77.6064V86.5097Z' fill='#D80027' />
      <path d='M50.8936 86.5098V90.395C53.4833 92.3438 56.5098 93.741 59.7978 94.4115V86.5098H50.8936Z' fill='#D80027' />
      <path d='M68.7021 86.5098V94.4115C71.9904 93.7415 75.0166 92.3438 77.6064 90.395V86.5098H68.7021Z' fill='#D80027' />
    </svg>
  );
}

function Hungary(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.23075 41.9893C1.65825 48.9228 0.25 56.4216 0.25 64.2501C0.25 72.0786 1.65825 79.5778 4.23075 86.5108L64.25 92.0761L124.269 86.5108C126.842 79.5778 128.25 72.0786 128.25 64.2501C128.25 56.4216 126.842 48.9228 124.269 41.9893L64.25 36.4241L4.23075 41.9893Z' fill='#F0F0F0' />
      <path d='M64.25 0.25C36.7325 0.25 13.2735 17.618 4.23096 41.9893H124.27C115.226 17.618 91.768 0.25 64.25 0.25Z' fill='#D80027' />
      <path d='M64.25 128.25C91.768 128.25 115.226 110.882 124.269 86.5107H4.23096C13.2735 110.882 36.7325 128.25 64.25 128.25Z' fill='#6DA544' />
    </svg>
  );
}

function Indonesia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M0.25 64.25C0.25 28.904 28.904 0.25 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25' fill='#A2001D' />
    </svg>
  );
}

function Israel(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M88.3484 50.3371H72.2826L64.2501 36.4241L56.2179 50.3371H40.1519L48.1854 64.2501L40.1519 78.1631H56.2179L64.2501 92.0761L72.2826 78.1631H88.3484L80.3149 64.2501L88.3484 50.3371ZM74.1189 64.2501L69.1849 72.7971H59.3161L54.3814 64.2501L59.3159 55.7031H69.1846L74.1189 64.2501ZM64.2501 47.1558L66.0866 50.3368H62.4141L64.2501 47.1558ZM49.4466 55.7031H53.1196L51.2831 58.8841L49.4466 55.7031ZM49.4466 72.7971L51.2834 69.6161L53.1199 72.7971H49.4466ZM64.2501 81.3441L62.4139 78.1631H66.0866L64.2501 81.3441ZM79.0536 72.7971H75.3806L77.2174 69.6161L79.0536 72.7971ZM75.3806 55.7031H79.0536L77.2171 58.8841L75.3806 55.7031Z' fill='#0052B4' />
      <path d='M104.089 14.1631H24.4108C18.5701 18.8151 13.5611 24.4656 9.64355 30.8588H118.857C114.939 24.4658 109.93 18.8151 104.089 14.1631Z' fill='#0052B4' />
      <path d='M24.4108 114.337H104.089C109.93 109.685 114.939 104.035 118.856 97.6414H9.64355C13.5613 104.034 18.5703 109.685 24.4108 114.337Z' fill='#0052B4' />
    </svg>
  );
}

function India(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.248C99.5962 128.248 128.25 99.5938 128.25 64.2476C128.25 28.9013 99.5962 0.247559 64.25 0.247559C28.9038 0.247559 0.25 28.9013 0.25 64.2476C0.25 99.5938 28.9038 128.248 64.25 128.248Z' fill='#F0F0F0' />
      <path d='M64.2501 0.247559C38.8766 0.247559 16.9526 15.0138 6.60059 36.4216H121.899C111.548 15.0138 89.6233 0.247559 64.2501 0.247559Z' fill='#FF9811' />
      <path d='M64.2501 128.247C89.6233 128.247 111.548 113.481 121.9 92.0735H6.60059C16.9526 113.481 38.8766 128.247 64.2501 128.247Z' fill='#6DA544' />
      <path d='M64.25 86.5083C76.5443 86.5083 86.5108 76.5418 86.5108 64.2476C86.5108 51.9533 76.5443 41.9868 64.25 41.9868C51.9557 41.9868 41.9893 51.9533 41.9893 64.2476C41.9893 76.5418 51.9557 86.5083 64.25 86.5083Z' fill='#0052B4' />
      <path d='M64.2499 78.1605C71.9338 78.1605 78.1629 71.9314 78.1629 64.2475C78.1629 56.5635 71.9338 50.3345 64.2499 50.3345C56.566 50.3345 50.3369 56.5635 50.3369 64.2475C50.3369 71.9314 56.566 78.1605 64.2499 78.1605Z' fill='#F0F0F0' />
      <path d='M64.2501 47.0791L68.5423 56.8136L79.1183 55.6633L72.8343 64.2476L79.1183 72.8319L68.5423 71.6816L64.2501 81.4161L59.9578 71.6816L49.3818 72.8316L55.6658 64.2476L49.3818 55.6633L59.9578 56.8136L64.2501 47.0791Z' fill='#0052B4' />
    </svg>
  );
}

function Iraq(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.25 0.25C36.7322 0.25 13.2735 17.618 4.23071 41.9893H124.269C115.226 17.618 91.7677 0.25 64.25 0.25Z' fill='#A2001D' />
      <path d='M64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5107H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25Z' fill='black' />
      <path d='M48.9457 60.076C48.9107 60.076 48.8765 60.0782 48.8415 60.0787V60.076H36.6072C37.2275 57.6795 39.402 55.902 41.9892 55.902V47.5542C34.3175 47.5542 28.0762 53.7957 28.0762 61.4672V68.2847V68.424H48.8415H48.9457C49.713 68.424 50.337 69.0482 50.337 69.8152V72.5977H22.5107V80.9455H58.6847V69.815C58.6847 64.445 54.3157 60.076 48.9457 60.076Z' fill='#496E2D' />
      <path d='M69.8153 72.5977V47.5542H61.4675V80.9457H75.3805V72.5977H69.8153Z' fill='#496E2D' />
      <path d='M97.6413 72.5979V47.5542H89.2936V72.5979H86.5108V64.2502H78.1631V80.9459H103.207V72.5979H97.6413Z' fill='#496E2D' />
    </svg>
  );
}

function Iran(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5967 128.25 64.2505C128.25 28.9043 99.5962 0.250488 64.25 0.250488C28.9038 0.250488 0.25 28.9043 0.25 64.2505C0.25 99.5967 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M85.0366 47.5547H76.6631C76.7324 48.4749 76.7716 49.4034 76.7716 50.3372C76.7716 56.5282 75.2241 62.5289 72.5264 66.8004C71.6941 68.1179 70.2956 69.9784 68.4236 71.2334V47.5544H60.0759V71.2334C58.2041 69.9784 56.8054 68.1182 55.9731 66.8004C53.2751 62.5289 51.7279 56.5282 51.7279 50.3372C51.7279 49.4034 51.7674 48.4747 51.8364 47.5547H43.4629C43.4091 48.4704 43.3799 49.3979 43.3799 50.3372C43.3799 67.5012 52.5469 80.9459 64.2494 80.9459C75.9519 80.9459 85.1189 67.5012 85.1189 50.3372C85.1194 49.3979 85.0901 48.4704 85.0366 47.5547Z' fill='#D80027' />
      <path d='M26.6848 30.8592H35.0326V36.4245H43.3803V30.8592H51.7281V36.4245H60.0758V30.8592H68.4236V36.4245H76.7713V30.8592H85.1191V36.4245H93.4668V30.8592H101.815V36.4245H121.899C111.548 15.0167 89.6233 0.250488 64.2501 0.250488C38.8768 0.250488 16.9526 15.0167 6.60059 36.4245H26.6848V30.8592Z' fill='#6DA544' />
      <path d='M101.815 92.0764V97.6417H93.4676V92.0764H85.1198V97.6417H76.7721V92.0764H68.4243V97.6417H60.0766V92.0764H51.7288V97.6417H43.3811V92.0764H35.0326V97.6417H26.6848V92.0764H6.60059C16.9526 113.484 38.8766 128.25 64.2501 128.25C89.6236 128.25 111.548 113.484 121.9 92.0764H101.815Z' fill='#D80027' />
    </svg>
  );
}

function Jordan(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5967 128.25 64.2505C128.25 28.9043 99.5962 0.250488 64.25 0.250488C28.9038 0.250488 0.25 28.9043 0.25 64.2505C0.25 99.5967 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M39.2066 41.9897H124.269C115.227 17.6185 91.7676 0.250488 64.2501 0.250488C46.5766 0.250488 30.5783 7.41524 18.9973 18.9977L39.2066 41.9897Z' fill='black' />
      <path d='M39.2066 86.5112H124.269C115.227 110.882 91.7676 128.25 64.2501 128.25C46.5766 128.25 30.5783 121.086 18.9973 109.503L39.2066 86.5112Z' fill='#6DA544' />
      <path d='M18.9949 18.9956C-5.99862 43.9891 -5.99862 84.5118 18.9949 109.506C29.3231 99.1774 39.2564 89.2441 64.2499 64.2506L18.9949 18.9956Z' fill='#D80027' />
      <path d='M25.6514 50.3374L29.1636 57.6822L37.0959 55.8489L33.5434 63.1742L39.9221 68.2326L31.9804 70.0224L32.0024 78.1634L25.6514 73.0701L19.3001 78.1634L19.3224 70.0224L11.3806 68.2326L17.7591 63.1742L14.2071 55.8489L22.1389 57.6822L25.6514 50.3374Z' fill='#F0F0F0' />
    </svg>
  );
}

function Japan(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.248C99.5962 128.248 128.25 99.5938 128.25 64.2476C128.25 28.9013 99.5962 0.247559 64.25 0.247559C28.9038 0.247559 0.25 28.9013 0.25 64.2476C0.25 99.5938 28.9038 128.248 64.25 128.248Z' fill='#F0F0F0' />
      <path d='M64.2501 92.0736C79.6179 92.0736 92.0761 79.6155 92.0761 64.2476C92.0761 48.8798 79.6179 36.4216 64.2501 36.4216C48.8822 36.4216 36.4241 48.8798 36.4241 64.2476C36.4241 79.6155 48.8822 92.0736 64.2501 92.0736Z' fill='#D80027' />
    </svg>
  );
}

function Kenya(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.25 0.25C38.8765 0.25 16.9525 15.0163 6.60046 36.424H121.899C111.547 15.0163 89.6232 0.25 64.25 0.25Z' fill='black' />
      <path d='M64.25 128.25C38.8765 128.25 16.9525 113.484 6.60046 92.0759H121.899C111.547 113.484 89.6232 128.25 64.25 128.25Z' fill='#496E2D' />
      <path d='M125.228 44.7717H3.27225C1.31175 50.914 0.25 57.4577 0.25 64.25C0.25 71.0422 1.31175 77.586 3.27225 83.7282H125.228C127.188 77.586 128.25 71.0422 128.25 64.25C128.25 57.4577 127.188 50.914 125.228 44.7717Z' fill='#A2001D' />
      <path d='M84.1788 29.8249L76.5995 26.3267L64.25 54.2894L51.9005 26.3267L44.3213 29.8249L59.6533 64.2497L44.3213 98.6744L51.9005 102.173L64.25 74.2099L76.5995 102.173L84.1788 98.6744L68.8468 64.2497L84.1788 29.8249Z' fill='#F0F0F0' />
      <path d='M69.3978 33.1219C66.4638 29.9177 64.25 28.0757 64.25 28.0757C64.25 28.0757 62.0363 29.9174 59.1023 33.1219V95.3769C62.0363 98.5817 64.25 100.424 64.25 100.424C64.25 100.424 66.4638 98.5819 69.3978 95.3772V33.1219Z' fill='#F0F0F0' />
      <path d='M50.3372 45.0606V83.4386C53.4377 88.9324 57.2324 93.4354 60.0764 96.4209V32.0786C57.2324 35.0639 53.4374 39.5671 50.3372 45.0606Z' fill='#A2001D' />
      <path d='M78.1632 45.0606C75.0625 39.5669 71.268 35.0639 68.424 32.0786V96.4209C71.2677 93.4354 75.0625 88.9324 78.1632 83.4386V45.0606Z' fill='#A2001D' />
      <path d='M78.163 45.0608V83.4388C81.3017 77.8765 83.7282 71.2993 83.7282 64.25C83.7282 57.2008 81.3017 50.6233 78.163 45.0608Z' fill='black' />
      <path d='M50.337 45.0608V83.4388C47.1982 77.8765 44.7717 71.2993 44.7717 64.25C44.7717 57.2008 47.1982 50.6233 50.337 45.0608Z' fill='black' />
    </svg>
  );
}

function Kyrgyzstan(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M95.5493 64.2501L82.7606 70.2656L89.5708 82.6523L75.6826 79.9951L73.9236 94.0241L64.2498 83.7066L54.5768 94.0241L52.8171 79.9951L38.9301 82.6518L45.7401 70.2651L32.9508 64.2501L45.7401 58.2348L38.9301 45.8478L52.8166 48.5053L54.5773 34.4761L64.2498 44.7938L73.9241 34.4761L75.6826 48.5053L89.5713 45.8478L82.7608 58.2356L95.5493 64.2501Z' fill='#FFDA44' />
      <path d='M64.25 83.7282C75.0075 83.7282 83.7282 75.0075 83.7282 64.25C83.7282 53.4924 75.0075 44.7717 64.25 44.7717C53.4924 44.7717 44.7717 53.4924 44.7717 64.25C44.7717 75.0075 53.4924 83.7282 64.25 83.7282Z' fill='#D80027' />
      <path d='M54.5106 64.2502C54.0414 64.2502 53.5781 64.2742 53.1208 64.3197C53.1376 67.0175 54.1136 69.4867 55.7256 71.405C56.5856 69.0907 57.8696 66.9805 59.4888 65.1745C57.9423 64.5797 56.2646 64.2502 54.5106 64.2502Z' fill='#FFDA44' />
      <path d='M60.5061 74.7335C61.6761 75.1515 62.9359 75.3807 64.2499 75.3807C65.5639 75.3807 66.8236 75.1515 67.9936 74.7335C67.3559 72.2305 66.0391 69.996 64.2499 68.24C62.4606 69.9957 61.1439 72.2305 60.5061 74.7335Z' fill='#FFDA44' />
      <path d='M73.8886 58.6866C71.9641 55.3601 68.3698 53.1196 64.2498 53.1196C60.1298 53.1196 56.5356 55.3599 54.6111 58.6866C58.1196 58.7044 61.4096 59.6584 64.2498 61.3056C67.0901 59.6584 70.3796 58.7044 73.8886 58.6866Z' fill='#FFDA44' />
      <path d='M69.0104 65.1747C70.6296 66.9805 71.9136 69.0907 72.7736 71.4052C74.3856 69.487 75.3616 67.0177 75.3784 64.32C74.9209 64.2742 74.4576 64.2505 73.9886 64.2505C72.2351 64.2502 70.5574 64.5797 69.0104 65.1747Z' fill='#FFDA44' />
    </svg>
  );
}

function SouthKorea(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5957 128.25 64.2495C128.25 28.9033 99.5962 0.249512 64.25 0.249512C28.9038 0.249512 0.25 28.9033 0.25 64.2495C0.25 99.5957 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M86.5108 64.2495C86.5108 69.8148 76.5443 83.7278 64.25 83.7278C51.9558 83.7278 41.9893 69.8148 41.9893 64.2495C41.9893 51.955 51.9558 41.9888 64.25 41.9888C76.5443 41.9888 86.5108 51.955 86.5108 64.2495Z' fill='#D80027' />
      <path d='M86.5108 64.2495C86.5108 76.544 76.5443 86.5103 64.25 86.5103C51.9558 86.5103 41.9893 76.544 41.9893 64.2495' fill='#0052B4' />
      <path d='M87.8438 83.9265L93.7456 78.0247L97.6802 81.9593L91.7784 87.8611L87.8438 83.9265Z' fill='black' />
      <path d='M78.0044 93.7633L83.9063 87.8614L87.8409 91.7961L81.939 97.6979L78.0044 93.7633Z' fill='black' />
      <path d='M99.6484 95.7297L105.55 89.8279L109.485 93.7625L103.583 99.6644L99.6484 95.7297Z' fill='black' />
      <path d='M89.8091 105.568L95.7109 99.6663L99.6456 103.601L93.7437 109.503L89.8091 105.568Z' fill='black' />
      <path d='M93.7456 89.8286L99.6475 83.9268L103.582 87.8614L97.6802 93.7632L93.7456 89.8286Z' fill='black' />
      <path d='M83.9092 99.6653L89.811 93.7634L93.7457 97.6981L87.8438 103.6L83.9092 99.6653Z' fill='black' />
      <path d='M99.6743 44.5834L83.936 28.845L87.8706 24.9104L103.609 40.6487L99.6743 44.5834Z' fill='black' />
      <path d='M83.9331 40.6503L78.0312 34.7484L81.9657 30.814L87.8676 36.7158L83.9331 40.6503Z' fill='black' />
      <path d='M93.7695 50.4893L87.8677 44.5874L91.8021 40.6529L97.704 46.5548L93.7695 50.4893Z' fill='black' />
      <path d='M95.7358 28.8445L89.834 22.9426L93.7686 19.008L99.6705 24.9099L95.7358 28.8445Z' fill='black' />
      <path d='M105.578 38.6846L99.6758 32.7827L103.61 28.8481L109.512 34.7499L105.578 38.6846Z' fill='black' />
      <path d='M22.9385 89.8175L38.6768 105.556L34.7422 109.49L19.0038 93.7521L22.9385 89.8175Z' fill='black' />
      <path d='M38.6763 93.749L44.5781 99.6509L40.6437 103.585L34.7418 97.6835L38.6763 93.749Z' fill='black' />
      <path d='M28.8389 83.9144L34.7407 89.8163L30.8061 93.7509L24.9042 87.8491L28.8389 83.9144Z' fill='black' />
      <path d='M34.7412 78.0109L50.4796 93.7492L46.5449 97.6838L30.8066 81.9455L34.7412 78.0109Z' fill='black' />
      <path d='M38.6753 22.9304L22.9369 38.6688L19.0023 34.7341L34.7407 18.9958L38.6753 22.9304Z' fill='black' />
      <path d='M44.5771 28.8317L28.8388 44.57L24.9042 40.6354L40.6425 24.897L44.5771 28.8317Z' fill='black' />
      <path d='M50.4849 34.7371L34.7465 50.4754L30.8121 46.541L46.5504 30.8026L50.4849 34.7371Z' fill='black' />
    </svg>
  );
}

function Kuwait(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M124.269 86.5108C126.842 79.5776 128.25 72.0786 128.25 64.2501C128.25 56.4216 126.842 48.9226 124.269 41.9893L64.25 36.4241L4.23075 41.9893C1.65825 48.9226 0.25 56.4216 0.25 64.2501C0.25 72.0786 1.65825 79.5776 4.23075 86.5108L64.25 92.0761L124.269 86.5108Z' fill='#F0F0F0' />
      <path d='M64.25 128.25C91.7675 128.25 115.226 110.882 124.269 86.5107H4.23047C13.2735 110.882 36.732 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M64.25 0.25C36.732 0.25 13.2735 17.618 4.23047 41.9893H124.269C115.226 17.618 91.7675 0.25 64.25 0.25Z' fill='#6DA544' />
      <path d='M18.995 18.9951C-5.9985 43.9886 -5.9985 84.5114 18.995 109.505C25.5188 102.981 31.8862 96.6139 41.989 86.5111V41.9894L18.995 18.9951Z' fill='black' />
    </svg>
  );
}

function Lebanon(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.2501 0.25C38.8766 0.25 16.9526 15.0163 6.60059 36.424H121.899C111.548 15.0163 89.6233 0.25 64.2501 0.25Z' fill='#D80027' />
      <path d='M64.2501 128.25C89.6233 128.25 111.548 113.484 121.9 92.0759H6.60059C16.9526 113.484 38.8766 128.25 64.2501 128.25Z' fill='#D80027' />
      <path d='M80.9458 75.3805L64.25 44.7717L47.5543 75.3805H60.076V83.7282H68.424V75.3805H80.9458Z' fill='#6DA544' />
    </svg>
  );
}

function SriLanka(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.25025 11.3805H0.25V117.119H0.25025V64.2502V11.3805Z' fill='#FF9811' />
      <path d='M64.2497 128.249C99.5958 128.249 128.249 99.5958 128.249 64.2497C128.249 28.9037 99.5958 0.25 64.2497 0.25C28.9037 0.25 0.25 28.9037 0.25 64.2497C0.25 99.5958 28.9037 128.249 64.2497 128.249Z' fill='#FFDA44' />
      <path d='M50.3373 11.3805H28.1785C27.1965 12.0517 26.2355 12.7505 25.2938 13.4737L19.7285 64.2497L25.2938 115.026C26.2355 115.749 27.1965 116.448 28.1785 117.119H50.3373V11.3805Z' fill='#FF9811' />
      <path d='M0.250244 64.2504C0.250244 84.9366 10.0672 103.327 25.2937 115.026V13.4741C10.0672 25.1736 0.250244 43.5641 0.250244 64.2504Z' fill='#6DA544' />
      <path d='M103.207 39.2661V40.8674L103.266 40.8764L103.207 39.2661Z' fill='#FFDA44' />
      <path d='M123.149 80.9455H111.091L105.989 86.5107V97.6412H94.8588V92.076H100.424V80.9455H75.3806V87.902H69.8153V74.6442C68.1078 73.1157 67.0328 70.8955 67.0328 68.4237V32.25C67.0328 27.6397 70.7703 23.9022 75.3806 23.9022V75.3805H86.5111L90.3301 72.1977C89.6631 70.609 89.2936 68.8642 89.2936 67.0327V58.685H80.9458V41.9895H97.6413C97.6413 39.207 101.815 36.4242 101.815 36.4242C101.815 36.4242 105.989 39.2067 105.989 41.9895V46.1635V58.6852C109.085 58.722 114.004 58.6417 119.317 58.6852C117.921 56.2452 117.12 53.35 117.12 50.3375C117.12 45.4272 119.24 41.0127 122.614 37.9577C117.75 27.179 109.989 17.9885 100.322 11.3805H58.6851V117.119H100.322C112.077 109.084 121.014 97.2315 125.35 83.347L123.149 80.9455Z' fill='#A2001D' />
    </svg>
  );
}

function Morocco(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M102.072 52.7725H73.1787L64.2502 25.2935L55.3217 52.7725H26.4282L49.8035 69.7555L40.8747 97.2352L64.2502 80.252L87.6257 97.2355L78.697 69.7557L102.072 52.7725ZM56.3457 67.6297L59.365 58.3375H69.1352L72.1547 67.6297V67.63L64.2502 73.3727L56.346 67.63L56.3457 67.6297ZM67.3272 52.7725H61.1735L64.2502 43.3025L67.3272 52.7725ZM76.8885 64.1905L74.9867 58.3377H84.944L76.8885 64.1905ZM53.5135 58.3377L51.6117 64.1905L43.556 58.3377H53.5135ZM51.4605 82.6652L54.5375 73.1955L59.5162 76.8125L51.4605 82.6652ZM68.9842 76.8127L73.963 73.1957L77.04 82.6655L68.9842 76.8127Z' fill='#FFDA44' />
    </svg>
  );
}

function Moldova(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M92.0759 6.6005C83.6619 2.532 74.2224 0.25 64.2499 0.25C54.2774 0.25 44.8379 2.532 36.4239 6.6005L30.8586 64.25L36.4239 121.899C44.8379 125.968 54.2774 128.25 64.2499 128.25C74.2224 128.25 83.6619 125.968 92.0759 121.899L97.6411 64.25L92.0759 6.6005Z' fill='#FFDA44' />
      <path d='M36.424 6.60272C15.0165 16.9545 0.25 38.8767 0.25 64.25C0.25 89.6232 15.0165 111.545 36.424 121.897V6.60272Z' fill='#0052B4' />
      <path d='M92.0759 6.60278V121.897C113.483 111.546 128.25 89.6233 128.25 64.25C128.25 38.8768 113.483 16.9545 92.0759 6.60278Z' fill='#D80027' />
      <path d='M86.5108 50.6048H71.2065C71.2065 46.7628 68.092 43.6483 64.25 43.6483C60.408 43.6483 57.2935 46.7628 57.2935 50.6048H41.9893C41.9893 54.3875 45.2845 57.4538 49.0668 57.4538H48.8388C48.8388 61.2368 51.9053 64.3035 55.6883 64.3035C55.6883 67.6528 58.0938 70.4358 61.2708 71.0308L55.8733 83.2163C58.4595 84.2675 61.286 84.8518 64.25 84.8518C67.2138 84.8518 70.0405 84.2675 72.6265 83.2163L67.2293 71.0308C70.4063 70.4358 72.8118 67.6528 72.8118 64.3035C76.5948 64.3035 79.6613 61.2368 79.6613 57.4538H79.433C83.2158 57.4538 86.5108 54.3873 86.5108 50.6048Z' fill='#FF9811' />
      <path d='M64.25 60.076L55.2065 64.25V72.5977L64.25 78.163L73.2935 72.5977V64.25L64.25 60.076Z' fill='#0052B4' />
      <path d='M55.2065 55.9022H73.2935V64.25H55.2065V55.9022Z' fill='#D80027' />
    </svg>
  );
}

function Mexico(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M128.25 64.25C128.25 38.8765 113.484 16.9525 92.0762 6.60046V121.899C113.484 111.547 128.25 89.6235 128.25 64.25Z' fill='#D80027' />
      <path d='M0.25 64.25C0.25 89.6235 15.0163 111.547 36.424 121.899V6.60046C15.0163 16.9525 0.25 38.8765 0.25 64.25Z' fill='#6DA544' />
      <path d='M47.5542 64.2499C47.5542 73.4707 55.0292 80.9457 64.25 80.9457C73.4707 80.9457 80.9457 73.4707 80.9457 64.2499V58.6847H47.5542V64.2499Z' fill='#6DA544' />
      <path d='M86.5108 53.1194H69.815C69.815 50.0459 67.3233 47.5542 64.2498 47.5542C61.1763 47.5542 58.6845 50.0459 58.6845 53.1194H41.9888C41.9888 56.1932 44.666 58.6847 47.7393 58.6847H47.554C47.554 61.7584 50.0455 64.25 53.1193 64.25C53.1193 67.3237 55.6108 69.8152 58.6845 69.8152H69.815C72.8888 69.8152 75.3803 67.3237 75.3803 64.25C78.454 64.25 80.9455 61.7584 80.9455 58.6847H80.76C83.8338 58.6847 86.5108 56.1932 86.5108 53.1194Z' fill='#FF9811' />
    </svg>
  );
}

function Malaysia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M61.4668 64.2498H128.249C128.249 58.4731 127.479 52.8776 126.045 47.5541H61.4668V64.2498Z' fill='#D80027' />
      <path d='M61.4668 30.8586H118.856C114.938 24.4656 109.929 18.8148 104.089 14.1628H61.4668V30.8586Z' fill='#D80027' />
      <path d='M64.2494 128.25C79.3117 128.25 93.1559 123.044 104.089 114.337H24.4102C35.3429 123.044 49.1872 128.25 64.2494 128.25Z' fill='#D80027' />
      <path d='M9.64285 97.6411H118.856C122.001 92.5091 124.44 86.8988 126.045 80.9453H2.4541C4.05835 86.8988 6.4976 92.5091 9.64285 97.6411Z' fill='#D80027' />
      <path d='M64.25 64.25C64.25 28.904 64.25 24.737 64.25 0.25C28.904 0.25 0.25 28.904 0.25 64.25H64.25Z' fill='#0052B4' />
      <path d='M42.8084 55.0325C34.0679 55.0325 26.9824 47.9468 26.9824 39.2065C26.9824 30.4663 34.0682 23.3805 42.8084 23.3805C45.5337 23.3805 48.0979 24.0698 50.3367 25.2828C46.8247 21.8485 42.0217 19.7285 36.7214 19.7285C25.9642 19.7285 17.2432 28.4493 17.2432 39.2068C17.2432 49.9643 25.9642 58.685 36.7214 58.685C42.0217 58.685 46.8247 56.565 50.3367 53.1308C48.0979 54.3433 45.5339 55.0325 42.8084 55.0325Z' fill='#FFDA44' />
      <path d='M47.2681 28.076L50.0778 33.9515L56.4231 32.4855L53.5816 38.3453L58.6846 42.392L52.3311 43.8243L52.3488 50.3371L47.2681 46.2626L42.1871 50.3371L42.2046 43.8243L35.8511 42.392L40.9541 38.3453L38.1123 32.4855L44.4581 33.9515L47.2681 28.076Z' fill='#FFDA44' />
    </svg>
  );
}

function Nigeria(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.2471 128.25C99.5933 128.25 128.247 99.5962 128.247 64.25C128.247 28.9038 99.5933 0.25 64.2471 0.25C28.9008 0.25 0.24707 28.9038 0.24707 64.25C0.24707 99.5962 28.9008 128.25 64.2471 128.25Z' fill='#F0F0F0' />
      <path d='M0.24707 64.25C0.24707 91.768 17.6151 115.227 41.9863 124.27V4.23102C17.6151 13.2735 0.24707 36.7325 0.24707 64.25Z' fill='#6DA544' />
      <path d='M128.247 64.25C128.247 36.7325 110.879 13.2735 86.5078 4.23102V124.27C110.879 115.227 128.247 91.768 128.247 64.25Z' fill='#6DA544' />
    </svg>
  );
}

function Nicaragua(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.2471 128.25C99.5933 128.25 128.247 99.5962 128.247 64.25C128.247 28.9038 99.5933 0.25 64.2471 0.25C28.9008 0.25 0.24707 28.9038 0.24707 64.25C0.24707 99.5962 28.9008 128.25 64.2471 128.25Z' fill='#F0F0F0' />
      <path d='M64.2472 0.25C38.8737 0.25 16.9497 15.0163 6.59766 36.424H121.896C111.545 15.0163 89.6204 0.25 64.2472 0.25Z' fill='#338AF3' />
      <path d='M64.2472 128.25C89.6204 128.25 111.545 113.484 121.897 92.076H6.59766C16.9497 113.484 38.8737 128.25 64.2472 128.25Z' fill='#338AF3' />
      <path d='M64.247 44.7717C53.4895 44.7717 44.7688 53.4925 44.7688 64.25C44.7688 75.0075 53.4895 83.7282 64.247 83.7282C75.0045 83.7282 83.7253 75.0075 83.7253 64.25C83.7253 53.4925 75.0045 44.7717 64.247 44.7717ZM64.247 75.3805C58.1 75.3805 53.1165 70.3975 53.1165 64.25C53.1165 58.1025 58.0998 53.1195 64.247 53.1195C70.3943 53.1195 75.3775 58.1025 75.3775 64.25C75.3775 70.3975 70.394 75.3805 64.247 75.3805Z' fill='#FFDA44' />
      <path d='M73.8865 67.0325L64.247 64.25L54.6075 67.0325L51.3945 72.5977H77.0993L73.8865 67.0325Z' fill='#0052B4' />
      <path d='M64.2471 50.337L57.8208 61.4675L64.2471 64.25L70.6733 61.4675L64.2471 50.337Z' fill='#338AF3' />
      <path d='M54.6077 67.0325H73.8867L70.6734 61.4675H57.8209L54.6077 67.0325Z' fill='#6DA544' />
    </svg>
  );
}

function Norway(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M2.45459 80.9457C6.15759 94.6855 14.3188 106.594 25.2933 115.026V80.9457H2.45459Z' fill='#D80027' />
      <path d='M58.6846 128.006C60.5191 128.164 62.3743 128.25 64.2498 128.25C93.8196 128.25 118.701 108.195 126.045 80.9457H58.6846V128.006Z' fill='#D80027' />
      <path d='M126.045 47.5542C118.701 20.3052 93.8196 0.25 64.2498 0.25C62.3743 0.25 60.5191 0.3355 58.6846 0.4935V47.5542H126.045Z' fill='#D80027' />
      <path d='M25.2933 13.4738C14.3188 21.9063 6.15759 33.8145 2.45459 47.5543H25.2933V13.4738Z' fill='#D80027' />
      <path d='M127.708 55.9022H50.3372H50.337V1.77124C44.4225 3.08274 38.8153 5.20849 33.6413 8.03174V55.9017V55.902H0.79175C0.4355 58.6347 0.25 61.4207 0.25 64.25C0.25 67.0792 0.4355 69.8652 0.79175 72.5977H33.641H33.6413V120.468C38.8153 123.291 44.4225 125.417 50.337 126.728V72.5985V72.598H127.708C128.064 69.8652 128.25 67.0792 128.25 64.25C128.25 61.4207 128.064 58.6347 127.708 55.9022Z' fill='#0052B4' />
    </svg>
  );
}

function NewZealand(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.247 64.25C128.247 99.596 99.5931 128.25 64.2471 128.25C28.9011 128.25 0.24707 99.596 0.24707 64.25C0.24707 64.2653 64.2471 0.257 64.2471 0.25C99.5931 0.25 128.247 28.904 128.247 64.25Z' fill='#0052B4' />
      <path d='M64.0759 64.25H64.2472C64.2472 64.1925 64.2472 64.1365 64.2472 64.0787C64.1902 64.136 64.1332 64.193 64.0759 64.25Z' fill='#F0F0F0' />
      <path d='M64.2471 33.6413C64.2471 22.38 64.2471 15.0013 64.2471 0.25H64.2363C28.8951 0.256 0.24707 28.9072 0.24707 64.25H33.6383V45.4473L52.4413 64.25H64.0761C64.1331 64.193 64.1903 64.136 64.2473 64.0788C64.2473 59.767 64.2473 55.9198 64.2473 52.4445L45.4441 33.6413H64.2471Z' fill='#F0F0F0' />
      <path d='M32.6257 8.59778C22.616 14.2975 14.2945 22.619 8.59473 32.6288V64.25H25.2905V25.294V25.2935H64.247C64.247 20.0278 64.247 15.0113 64.247 8.59778H32.6257Z' fill='#D80027' />
      <path d='M64.2469 56.3798L41.5087 33.6415C41.5087 33.6415 33.6384 33.642 33.6384 33.6415V33.642L64.2467 64.25H64.2469C64.2469 64.25 64.2469 58.8233 64.2469 56.3798Z' fill='#D80027' />
      <path d='M111.08 47.7397L112.461 51.9912H116.931L113.315 54.6187L114.696 58.87L111.08 56.2427L107.463 58.87L108.844 54.6187L105.228 51.9912H109.698L111.08 47.7397Z' fill='#D80027' />
      <path d='M95.0998 78.3483L97.172 84.7258H103.877L98.4523 88.6668L100.525 95.044L95.0998 91.1028L89.6748 95.044L91.7473 88.6668L86.3223 84.7258H93.0275L95.0998 78.3483Z' fill='#D80027' />
      <path d='M95.7032 28.2615L97.4302 33.5763H103.018L98.4969 36.86L100.224 42.1745L95.7032 38.8898L91.1827 42.1745L92.9094 36.86L88.3889 33.5763H93.9764L95.7032 28.2615Z' fill='#D80027' />
      <path d='M80.3275 47.5543L82.3995 53.9318H89.105L83.68 57.8728L85.7525 64.25L80.3275 60.3088L74.9025 64.25L76.975 57.8728L71.55 53.9318H78.2553L80.3275 47.5543Z' fill='#D80027' />
    </svg>
  );
}

function Oman(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M0.25 64.25C0.25 88.5087 13.7475 109.614 33.6413 120.468V8.03174C13.7475 18.8862 0.25 39.9912 0.25 64.25Z' fill='#D80027' />
      <path d='M124.27 86.5108C126.842 79.5775 128.25 72.0785 128.25 64.25C128.25 56.4215 126.842 48.9223 124.27 41.9893H4.231C1.65825 48.9223 0.25 56.4215 0.25 64.25C0.25 72.0785 1.65825 79.5775 4.231 86.5108L64.25 92.076L124.27 86.5108Z' fill='#D80027' />
      <path d='M64.25 128.25C91.768 128.25 115.226 110.882 124.269 86.5107H4.23096C13.2735 110.882 36.7325 128.25 64.25 128.25Z' fill='#6DA544' />
      <path d='M0.25 64.25C0.25 93.8198 20.3052 118.701 47.5542 126.045V2.45477C20.3052 9.79877 0.25 34.6803 0.25 64.25Z' fill='#D80027' />
      <path d='M39.4043 28.4135L33.5015 22.5107L29.5665 26.4462L25.6315 22.5107L19.7288 28.4135L23.664 32.3487L19.7283 36.2842L25.631 42.187L29.5663 38.2515L33.5015 42.187L39.4043 36.2842L35.469 32.3487L39.4043 28.4135Z' fill='#F0F0F0' />
    </svg>
  );
}

function Peru(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.5 128.25C99.8462 128.25 128.5 99.5962 128.5 64.25C128.5 28.9038 99.8462 0.25 64.5 0.25C29.1538 0.25 0.5 28.9038 0.5 64.25C0.5 99.5962 29.1538 128.25 64.5 128.25Z' fill='#F0F0F0' />
      <path d='M128.5 64.25C128.5 36.7325 111.132 13.2735 86.7607 4.23096V124.27C111.132 115.226 128.5 91.768 128.5 64.25Z' fill='#D80027' />
      <path d='M0.5 64.25C0.5 91.768 17.868 115.226 42.2393 124.269V4.23096C17.868 13.2735 0.5 36.7325 0.5 64.25Z' fill='#D80027' />
    </svg>
  );
}

function Philippines(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64 128.25C99.3462 128.25 128 99.5962 128 64.25C128 28.9038 99.3462 0.25 64 0.25C28.6538 0.25 0 28.9038 0 64.25C0 99.5962 28.6538 128.25 64 128.25Z' fill='#F0F0F0' />
      <path d='M64.0001 0.25V64.25L18.7451 109.505C30.3269 121.086 46.3269 128.25 64.0001 128.25C99.3461 128.25 128 99.596 128 64.25C128 28.904 64.0001 0.25 64.0001 0.25Z' fill='#D80027' />
      <path d='M64.0001 0.25C46.3269 0.25 30.3269 7.4135 18.7451 18.995C34.4476 34.6975 40.0219 40.272 64.0001 64.25H128C128 28.904 99.3461 0.25 64.0001 0.25Z' fill='#0052B4' />
      <path d='M43.8224 64.25L36.0064 60.5735L40.1687 53.0037L31.6812 54.6275L30.6057 46.0532L24.6937 52.3592L18.7819 46.0532L17.7062 54.6275L9.21894 53.0035L13.3812 60.5735L5.56494 64.25L13.3812 67.9265L9.21894 75.4962L17.7069 73.8727L18.7817 82.4467L24.6937 76.1407L30.6057 82.4467L31.6812 73.8727L40.1682 75.4965L36.0062 67.9267L43.8224 64.25Z' fill='#FFDA44' />
      <path d='M17.068 25.2935L19.669 28.9292L23.9302 27.5795L21.2757 31.1765L23.8767 34.8122L19.6352 33.3995L16.981 36.9962L17.0142 32.5262L12.7729 31.1132L17.0349 29.7635L17.068 25.2935Z' fill='#FFDA44' />
      <path d='M17.068 91.5037L19.669 95.1394L23.9302 93.7894L21.2757 97.3867L23.8767 101.022L19.6352 99.6097L16.981 103.206L17.0142 98.7364L12.7729 97.3234L17.0349 95.9737L17.068 91.5037Z' fill='#FFDA44' />
      <path d='M54.1662 58.3987L51.5655 62.0344L47.304 60.6842L49.9585 64.2814L47.3575 67.9174L51.599 66.5044L54.2535 70.1012L54.22 65.6314L58.4612 64.2184L54.1995 62.8687L54.1662 58.3987Z' fill='#FFDA44' />
    </svg>
  );
}

function Poland(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M128.25 64.25C128.25 99.596 99.596 128.25 64.25 128.25C28.904 128.25 0.25 99.596 0.25 64.25' fill='#D80027' />
    </svg>
  );
}

function Qatar(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.5 128.25C99.8462 128.25 128.5 99.5962 128.5 64.25C128.5 28.9038 99.8462 0.25 64.5 0.25C29.1538 0.25 0.5 28.9038 0.5 64.25C0.5 99.5962 29.1538 128.25 64.5 128.25Z' fill='#F0F0F0' />
      <path d='M128.5 64.25C128.5 28.904 99.846 0.25 64.5 0.25C51.98 0.25 40.3015 3.84875 30.437 10.0627L44.4652 16.023L25.5435 24.0625L44.4652 32.1017L25.5435 40.1408L44.4652 48.1795L25.5435 56.2173L44.4652 64.2545L25.5435 72.2935L44.4652 80.332L25.5435 88.3698L44.4652 96.4082L25.5435 104.447L44.4652 112.485L30.4432 118.442C40.3065 124.653 51.9825 128.25 64.5 128.25C99.846 128.25 128.5 99.596 128.5 64.25Z' fill='#751A46' />
    </svg>
  );
}

function Romania(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M86.5108 4.2305C79.5773 1.65825 72.0786 0.25 64.2501 0.25C56.4216 0.24975 48.9226 1.65825 41.9893 4.2305L36.4241 64.2497L41.9893 124.269C48.9223 126.842 56.4213 128.25 64.2501 128.25C72.0783 128.25 79.5776 126.842 86.5108 124.269L92.0758 64.25L86.5108 4.2305Z' fill='#FFDA44' />
      <path d='M128.25 64.25C128.25 36.7325 110.882 13.2732 86.5107 4.23071V124.27C110.882 115.226 128.25 91.768 128.25 64.25Z' fill='#D80027' />
      <path d='M0.25 64.2497C0.25 91.768 17.618 115.226 41.9888 124.269L41.989 4.23096C17.618 13.2735 0.25 36.732 0.25 64.2497H0.25Z' fill='#0052B4' />
    </svg>
  );
}

function Russia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M124.269 86.5108C126.842 79.5775 128.25 72.0785 128.25 64.25C128.25 56.4215 126.842 48.9225 124.269 41.9893H4.23075C1.65825 48.9225 0.25 56.4215 0.25 64.25C0.25 72.0785 1.65825 79.5775 4.23075 86.5108L64.25 92.076L124.269 86.5108Z' fill='#0052B4' />
      <path d='M64.25 128.25C91.7677 128.25 115.226 110.882 124.269 86.5107H4.23071C13.2735 110.882 36.7322 128.25 64.25 128.25Z' fill='#D80027' />
    </svg>
  );
}

function SaudiArabia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#6DA544' />
      <path d='M36.4238 76.7716C36.4238 81.3819 40.1613 85.1194 44.7716 85.1194H69.8151C69.8151 88.9614 72.9296 92.0759 76.7716 92.0759H85.1193C88.9613 92.0759 92.0758 88.9614 92.0758 85.1194V76.7716H36.4238Z' fill='#F0F0F0' />
      <path d='M92.7716 36.4238V55.9021C92.7716 58.9708 90.275 61.4673 87.2063 61.4673V69.8151C94.878 69.8151 101.119 63.5736 101.119 55.9021V36.4238H92.7716Z' fill='#F0F0F0' />
      <path d='M32.9456 55.9021C32.9456 58.9708 30.4491 61.4673 27.3804 61.4673V69.8151C35.0521 69.8151 41.2934 63.5736 41.2934 55.9021V36.4238H32.9456V55.9021Z' fill='#F0F0F0' />
      <path d='M80.2498 36.4238H88.5975V55.9021H80.2498V36.4238Z' fill='#F0F0F0' />
      <path d='M67.728 47.5541C67.728 48.3213 67.1038 48.9453 66.3368 48.9453C65.5698 48.9453 64.9455 48.3211 64.9455 47.5541V36.4236H56.5978V47.5541C56.5978 48.3213 55.9735 48.9453 55.2065 48.9453C54.4395 48.9453 53.8153 48.3211 53.8153 47.5541V36.4236H45.4673V47.5541C45.4673 52.9243 49.8363 57.2933 55.2065 57.2933C57.2748 57.2933 59.1928 56.6433 60.7718 55.5393C62.3505 56.6431 64.2688 57.2933 66.337 57.2933C66.7535 57.2933 67.163 57.2641 67.5658 57.2128C66.9743 59.6501 64.7793 61.4671 62.163 61.4671V69.8148C69.8348 69.8148 76.076 63.5733 76.076 55.9018V47.5541V36.4236H67.7283V47.5541H67.728Z' fill='#F0F0F0' />
      <path d='M45.4673 61.4673H57.989V69.815H45.4673V61.4673Z' fill='#F0F0F0' />
    </svg>
  );
}

function Sweden(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.1445 128.25C99.4908 128.25 128.145 99.5957 128.145 64.2495C128.145 28.9033 99.4908 0.249512 64.1445 0.249512C28.7983 0.249512 0.144531 28.9033 0.144531 64.2495C0.144531 99.5957 28.7983 128.25 64.1445 128.25Z' fill='#FFDA44' />
      <path d='M50.2322 55.9015H127.603C123.512 24.4998 96.6617 0.249268 64.1449 0.249268C59.3664 0.249268 54.7117 0.777518 50.2319 1.77052V55.9015H50.2322Z' fill='#0052B4' />
      <path d='M33.536 55.9013V8.03101C15.9625 17.6195 3.38227 35.2073 0.686523 55.9015H33.536V55.9013Z' fill='#0052B4' />
      <path d='M33.5358 72.5969H0.686523C3.38227 93.2912 15.9625 110.879 33.536 120.467L33.5358 72.5969Z' fill='#0052B4' />
      <path d='M50.2319 72.5972V126.728C54.7117 127.721 59.3664 128.249 64.1449 128.249C96.6617 128.249 123.512 103.999 127.603 72.5969H50.2319V72.5972Z' fill='#0052B4' />
    </svg>
  );
}

function Singapore(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M0.25 64.2503C0.25 28.904 28.904 0.25 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.2503' fill='#D80027' />
      <path d='M39.2064 33.641C39.2064 24.3165 45.7596 16.527 54.5106 14.6157C53.1656 14.3222 51.7704 14.163 50.3366 14.163C39.5789 14.163 30.8584 22.8835 30.8584 33.6412C30.8584 44.399 39.5789 53.1195 50.3366 53.1195C51.7699 53.1195 53.1654 52.9602 54.5106 52.6665C45.7596 50.7552 39.2064 42.9657 39.2064 33.641Z' fill='#F0F0F0' />
      <path d='M64.2499 15.5542L65.6314 19.8054H70.1017L66.4849 22.4332L67.8664 26.6844L64.2499 24.0572L60.6334 26.6844L62.0149 22.4332L58.3984 19.8054H62.8684L64.2499 15.5542Z' fill='#F0F0F0' />
      <path d='M53.4062 23.902L54.7874 28.1535H59.2577L55.6412 30.781L57.0227 35.0322L53.4062 32.405L49.7892 35.0322L51.171 30.781L47.5542 28.1535H52.0245L53.4062 23.902Z' fill='#F0F0F0' />
      <path d='M75.0937 23.902L76.4754 28.1535H80.9454L77.3287 30.781L78.7104 35.0322L75.0937 32.405L71.4772 35.0322L72.8587 30.781L69.2422 28.1535H73.7122L75.0937 23.902Z' fill='#F0F0F0' />
      <path d='M70.9203 36.4237L72.3018 40.6752H76.7719L73.1553 43.3027L74.5368 47.554L70.9203 44.9267L67.3038 47.554L68.6853 43.3027L65.0688 40.6752H69.5386L70.9203 36.4237Z' fill='#F0F0F0' />
      <path d='M57.5798 36.4237L58.961 40.6752H63.4315L59.8148 43.3027L61.1965 47.554L57.5798 44.9267L53.963 47.554L55.3445 43.3027L51.728 40.6752H56.1983L57.5798 36.4237Z' fill='#F0F0F0' />
    </svg>
  );
}

function Thailand(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5967 128.25 64.2505C128.25 28.9043 99.5962 0.250488 64.25 0.250488C28.9038 0.250488 0.25 28.9043 0.25 64.2505C0.25 99.5967 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M124.269 41.9897H4.23075C1.658 48.923 0.25 56.422 0.25 64.2505C0.25 72.079 1.658 79.578 4.23075 86.5112H124.27C126.842 79.578 128.25 72.079 128.25 64.2505C128.25 56.422 126.842 48.923 124.269 41.9897Z' fill='#0052B4' />
      <path d='M64.2506 0.250732C44.8529 0.250732 27.4721 8.88173 15.7354 22.5115H112.766C101.029 8.88173 83.6484 0.250732 64.2506 0.250732Z' fill='#D80027' />
      <path d='M112.766 105.99H15.7354C27.4721 119.62 44.8529 128.251 64.2506 128.251C83.6484 128.251 101.029 119.62 112.766 105.99Z' fill='#D80027' />
    </svg>
  );
}

function Turkey(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.145C99.5962 128.145 128.25 99.4912 128.25 64.145C128.25 28.7988 99.5962 0.14502 64.25 0.14502C28.9038 0.14502 0.25 28.7988 0.25 64.145C0.25 99.4912 28.9038 128.145 64.25 128.145Z' fill='#D80027' />
      <path d='M61.6299 52.4417L66.8811 59.6779L75.3854 56.9204L70.1259 64.1504L75.3764 71.3864L66.8751 68.6184L61.6159 75.8484L61.6214 66.9081L53.1199 64.1401L61.6244 61.3826L61.6299 52.4417Z' fill='#F0F0F0' />
      <path d='M47.2988 82.232C37.3098 82.232 29.2118 74.134 29.2118 64.145C29.2118 54.156 37.3098 46.058 47.2988 46.058C50.4133 46.058 53.3438 46.8458 55.9023 48.232C51.8885 44.3065 46.3993 41.884 40.3423 41.884C28.0478 41.884 18.0815 51.8505 18.0815 64.1448C18.0815 76.439 28.048 86.4055 40.3423 86.4055C46.3998 86.4055 51.8888 83.9828 55.9023 80.0575C53.3438 81.4443 50.4133 82.232 47.2988 82.232Z' fill='#F0F0F0' />
    </svg>
  );
}

function Taiwan(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M128.25 64.2495C128.25 99.5955 99.596 128.25 64.25 128.25C28.904 128.25 0.25 99.5955 0.25 64.2495C22.2325 42.267 40.876 23.6235 64.25 0.249512C99.596 0.249512 128.25 28.9035 128.25 64.2495Z' fill='#D80027' />
      <path d='M64.25 64.2495C64.25 28.9035 64.25 24.7365 64.25 0.249512C28.904 0.249512 0.25 28.9035 0.25 64.2495H64.25Z' fill='#0052B4' />
      <path d='M55.9023 37.7048L48.086 41.3818L52.248 48.9515L43.7608 47.3275L42.685 55.9018L36.7735 49.5958L30.8615 55.9018L29.7863 47.3275L21.2988 48.951L25.4608 41.3813L17.6448 37.7048L25.461 34.0285L21.2988 26.4585L29.786 28.0823L30.8618 19.5083L36.7735 25.8143L42.6853 19.5083L43.7608 28.0823L52.2483 26.4585L48.086 34.0288L55.9023 37.7048Z' fill='#F0F0F0' />
      <path d='M36.7746 49.6168C43.3541 49.6168 48.6878 44.283 48.6878 37.7035C48.6878 31.124 43.3541 25.7903 36.7746 25.7903C30.1951 25.7903 24.8613 31.124 24.8613 37.7035C24.8613 44.283 30.1951 49.6168 36.7746 49.6168Z' fill='#0052B4' />
      <path d='M36.7735 44.0527C33.2733 44.0527 30.4258 41.2049 30.4258 37.7047C30.4258 34.2044 33.2735 31.3567 36.7735 31.3567C40.2738 31.3567 43.1215 34.2044 43.1215 37.7047C43.1213 41.2049 40.2733 44.0527 36.7735 44.0527Z' fill='#F0F0F0' />
    </svg>
  );
}

function Ukraine(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#FFDA44' />
      <path d='M0.25 64.25C0.25 28.904 28.904 0.25 64.25 0.25C99.596 0.25 128.25 28.904 128.25 64.25' fill='#338AF3' />
    </svg>
  );
}

function UnitedStates(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5957 128.25 64.2495C128.25 28.9033 99.5962 0.249512 64.25 0.249512C28.9038 0.249512 0.25 28.9033 0.25 64.2495C0.25 99.5957 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M61.4673 64.2495H128.25C128.25 58.473 127.48 52.877 126.045 47.5537H61.4673V64.2495Z' fill='#D80027' />
      <path d='M61.4673 30.8581H118.856C114.939 24.4651 109.929 18.8144 104.089 14.1624H61.4673V30.8581Z' fill='#D80027' />
      <path d='M64.2499 128.249C79.3121 128.249 93.1564 123.043 104.089 114.336H24.4109C35.3434 123.043 49.1876 128.249 64.2499 128.249Z' fill='#D80027' />
      <path d='M9.64334 97.6406H118.856C122.002 92.5083 124.441 86.8983 126.045 80.9448H2.45459C4.05884 86.8983 6.49809 92.5083 9.64334 97.6406Z' fill='#D80027' />
      <path d='M29.896 10.244H35.7282L30.3032 14.1853L32.3755 20.5625L26.9508 16.6213L21.526 20.5625L23.316 15.0533C18.5395 19.032 14.353 23.6935 10.903 28.8875H12.7718L9.3185 31.3963C8.7805 32.2938 8.2645 33.2055 7.77 34.1308L9.419 39.206L6.3425 36.9708C5.57775 38.591 4.87825 40.2478 4.2495 41.939L6.06625 47.531H12.7718L7.34675 51.4723L9.419 57.8495L3.99425 53.9083L0.74475 56.2693C0.4195 58.8838 0.25 61.5468 0.25 64.2495H64.25C64.25 28.9035 64.25 24.7365 64.25 0.249512C51.607 0.249512 39.8213 3.91701 29.896 10.244ZM32.3755 57.8495L26.9508 53.9083L21.526 57.8495L23.5982 51.4723L18.1733 47.531H24.8787L26.9508 41.1538L29.0228 47.531H35.7282L30.3032 51.4723L32.3755 57.8495ZM30.3032 32.8288L32.3755 39.206L26.9508 35.2648L21.526 39.206L23.5982 32.8288L18.1733 28.8875H24.8787L26.9508 22.5103L29.0228 28.8875H35.7282L30.3032 32.8288ZM55.332 57.8495L49.9072 53.9083L44.4825 57.8495L46.5547 51.4723L41.1297 47.531H47.8353L49.9072 41.1538L51.9793 47.531H58.6847L53.2598 51.4723L55.332 57.8495ZM53.2598 32.8288L55.332 39.206L49.9072 35.2648L44.4825 39.206L46.5547 32.8288L41.1297 28.8875H47.8353L49.9072 22.5103L51.9793 28.8875H58.6847L53.2598 32.8288ZM53.2598 14.1853L55.332 20.5625L49.9072 16.6213L44.4825 20.5625L46.5547 14.1853L41.1297 10.244H47.8353L49.9072 3.86676L51.9793 10.244H58.6847L53.2598 14.1853Z' fill='#0052B4' />
    </svg>
  );
}

function Uruguay(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.145C99.5962 128.145 128.25 99.4912 128.25 64.145C128.25 28.7988 99.5962 0.14502 64.25 0.14502C28.9038 0.14502 0.25 28.7988 0.25 64.145C0.25 99.4912 28.9038 128.145 64.25 128.145Z' fill='#F0F0F0' />
      <path d='M64.25 47.4492H126.045C124.44 41.4957 122.001 35.8854 118.856 30.7534H64.25V47.4492Z' fill='#338AF3' />
      <path d='M24.4108 114.232H104.089C109.93 109.58 114.939 103.929 118.856 97.5361H9.64355C13.5613 103.929 18.5703 109.58 24.4108 114.232Z' fill='#338AF3' />
      <path d='M64.25 0.144775V14.0578H104.089C93.1567 5.35078 79.3123 0.144775 64.25 0.144775Z' fill='#338AF3' />
      <path d='M64.25 47.4489H126.045C124.44 41.4954 122.001 35.8852 118.856 30.7532H64.25V47.4489Z' fill='#338AF3' />
      <path d='M0.25 64.145C0.25 69.9218 1.02 75.5173 2.45475 80.8408H126.046C127.48 75.5173 128.25 69.9218 128.25 64.145H0.25Z' fill='#338AF3' />
      <path d='M55.9023 37.6003L48.0858 41.2771L52.248 48.8471L43.7605 47.2236L42.685 55.7973L36.7735 49.4913L30.8615 55.7973L29.7863 47.2236L21.2988 48.8466L25.4608 41.2768L17.6448 37.6003L25.461 33.9241L21.2988 26.3541L29.786 27.9776L30.8618 19.4038L36.7735 25.7098L42.6853 19.4038L43.7605 27.9776L52.2483 26.3541L48.086 33.9243L55.9023 37.6003Z' fill='#FFDA44' />
    </svg>
  );
}

function Venezuela(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M121.899 92.0759C125.968 83.6619 128.25 74.2226 128.25 64.2499C128.25 54.2771 125.968 44.8379 121.899 36.4239L64.25 30.8586L6.6005 36.4239C2.53175 44.8379 0.25 54.2771 0.25 64.2499C0.25 74.2226 2.53175 83.6619 6.6005 92.0759L64.25 97.6411L121.899 92.0759Z' fill='#0052B4' />
      <path d='M64.2501 128.25C89.6233 128.25 111.548 113.484 121.9 92.0759H6.60059C16.9526 113.484 38.8766 128.25 64.2501 128.25Z' fill='#D80027' />
      <path d='M111.092 76.8131L107.712 79.4546L109.179 83.4849L105.622 81.0866L102.242 83.7276L103.424 79.6039L99.8677 77.2049L104.155 77.0554L105.338 72.9316L106.805 76.9626L111.092 76.8131Z' fill='#F0F0F0' />
      <path d='M102.436 61.8206L100.163 65.4589L102.921 68.7444L98.758 67.7079L96.4845 71.3451L96.1847 67.0659L92.0225 66.0276L96.0005 64.4204L95.7015 60.1409L98.4587 63.4276L102.436 61.8206Z' fill='#F0F0F0' />
      <path d='M89.1741 50.6924L88.2826 54.8886L91.9976 57.0336L87.7314 57.4834L86.8394 61.6784L85.0944 57.7599L80.8276 58.2076L84.0161 55.3371L82.2714 51.4186L85.9864 53.5631L89.1741 50.6924Z' fill='#F0F0F0' />
      <path d='M72.906 44.772L73.5037 49.0202L77.728 49.7647L73.8725 51.6462L74.4697 55.8942L71.4897 52.8082L67.6332 54.6887L69.6477 50.9005L66.6675 47.815L70.8925 48.5597L72.906 44.772Z' fill='#F0F0F0' />
      <path d='M55.5947 44.772L57.609 48.5597L61.833 47.8145L58.8537 50.9012L60.867 54.6892L57.0115 52.8082L54.0309 55.8935L54.6287 51.6462L50.7729 49.7657L54.9977 49.0202L55.5947 44.772Z' fill='#F0F0F0' />
      <path d='M39.3271 50.6924L42.5156 53.5631L46.2299 51.4186L44.4856 55.3386L47.6734 58.2086L43.4071 57.7599L41.6616 61.6784L40.7709 57.4826L36.5044 57.0346L40.2194 54.8891L39.3271 50.6924Z' fill='#F0F0F0' />
      <path d='M26.0653 61.8206L30.0435 63.4276L32.8 60.1416L32.5018 64.4219L36.4788 66.0291L32.3165 67.0664L32.0168 71.3451L29.7443 67.7079L25.5815 68.7451L28.339 65.4589L26.0653 61.8206Z' fill='#F0F0F0' />
      <path d='M17.4092 76.8131L21.6972 76.9626L23.1637 72.9321L24.3474 77.0561L28.6342 77.2059L25.0777 79.6049L26.2597 83.7284L22.8797 81.0874L19.3229 83.4856L20.7902 79.4546L17.4092 76.8131Z' fill='#F0F0F0' />
      <path d='M121.9 36.424C111.548 15.0163 89.6233 0.25 64.2501 0.25C38.8766 0.25 16.9526 15.0163 6.60059 36.424H121.9Z' fill='#FFDA44' />
    </svg>
  );
}

function Vietnam(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#D80027' />
      <path d='M64.2502 33.6414L71.1572 54.8986H93.5084L75.4257 68.0361L82.3327 89.2936L64.2502 76.1559L46.1677 89.2936L53.0747 68.0361L34.9922 54.8986H57.3432L64.2502 33.6414Z' fill='#FFDA44' />
    </svg>
  );
}

function CentralAfricanRepublic(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.248C99.5962 128.248 128.25 99.5938 128.25 64.2476C128.25 28.9013 99.5962 0.247559 64.25 0.247559C28.9038 0.247559 0.25 28.9013 0.25 64.2476C0.25 99.5938 28.9038 128.248 64.25 128.248Z' fill='#F0F0F0' />
      <path d='M120.469 33.6386C112.199 18.4814 97.9781 7.03911 80.9463 2.44861L78.1638 33.6386H120.469Z' fill='#0052B4' />
      <path d='M8.03223 33.6386H50.3372L47.5547 2.44861C30.523 7.03911 16.3025 18.4816 8.03223 33.6386Z' fill='#0052B4' />
      <path d='M78.1631 64.2478V94.8566H120.468C125.429 85.7638 128.25 75.3353 128.25 64.2478H78.1631Z' fill='#6DA544' />
      <path d='M50.337 64.2478H0.25C0.25 75.3353 3.07075 85.7638 8.03175 94.8566H50.3368V64.2478H50.337Z' fill='#6DA544' />
      <path d='M8.03223 94.8564C16.3025 110.014 30.523 121.456 47.5547 126.046L50.3372 98.7692L8.03223 94.8564Z' fill='#FFDA44' />
      <path d='M80.9463 126.046C97.9781 121.456 112.199 110.014 120.469 94.8564L78.1638 98.7687L80.9463 126.046Z' fill='#FFDA44' />
      <path d='M78.1636 98.7687L120.469 94.8564H78.1636V98.7687Z' fill='#FFDA44' />
      <path d='M50.3375 94.8564H8.03223L50.3375 98.7687V94.8564Z' fill='#FFDA44' />
      <path d='M64.25 0.247559C58.4733 0.247559 52.8778 1.01756 47.5543 2.45231V126.043C52.8778 127.478 58.4733 128.248 64.25 128.248C70.0268 128.248 75.6223 127.478 80.9458 126.043V2.45231C75.6223 1.01756 70.0268 0.247559 64.25 0.247559V0.247559Z' fill='#D80027' />
      <path d='M34.6746 14.1606L36.4011 19.4748H41.9893L37.4688 22.7593L39.1956 28.0736L34.6746 24.7893L30.1538 28.0736L31.8811 22.7593L27.3606 19.4748H32.9483L34.6746 14.1606Z' fill='#FFDA44' />
    </svg>
  );
}

function Yemen(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M64.2502 128.25C91.768 128.25 115.227 110.882 124.269 86.5107H4.23096C13.2737 110.882 36.7325 128.25 64.2502 128.25Z' fill='black' />
      <path d='M64.2502 0.25C36.7325 0.25 13.2737 17.618 4.23096 41.9893H124.27C115.227 17.618 91.768 0.25 64.2502 0.25Z' fill='#D80027' />
    </svg>
  );
}

function SouthAfrica(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.25 128.25C99.5962 128.25 128.25 99.5962 128.25 64.25C128.25 28.9038 99.5962 0.25 64.25 0.25C28.9038 0.25 0.25 28.9038 0.25 64.25C0.25 99.5962 28.9038 128.25 64.25 128.25Z' fill='#F0F0F0' />
      <path d='M18.9951 109.505C-5.99837 84.5112 -5.99837 43.9887 18.9951 18.995C18.9934 18.9977 55.9024 64.25 55.9024 64.25L18.9951 109.505Z' fill='black' />
      <path d='M55.9022 64.25L8.73817 32.3783C7.27592 34.92 5.98642 37.573 4.87842 40.319L28.7557 64.2503L4.87917 88.1828C5.98617 90.9268 7.27442 93.578 8.73567 96.118L55.9022 64.25Z' fill='#FFDA44' />
      <path d='M127.708 55.9022H55.9006L18.9946 18.996C15.0176 22.973 11.5623 27.4707 8.73834 32.3785L40.5481 64.25L8.73584 96.1175C11.5598 101.026 15.0166 105.526 18.9946 109.504L55.9006 72.5977H127.708C128.064 69.8652 128.25 67.0792 128.25 64.25C128.25 61.4207 128.064 58.6347 127.708 55.9022Z' fill='#6DA544' />
      <path d='M25.2847 115.019C36.0769 123.315 49.5862 128.25 64.2502 128.25C93.8199 128.25 118.701 108.195 126.045 80.9458H59.3584L25.2847 115.019Z' fill='#0052B4' />
      <path d='M126.045 47.5542C118.701 20.3052 93.8199 0.25 64.2502 0.25C49.5862 0.25 36.0769 5.18525 25.2847 13.4808L59.3582 47.5542H126.045Z' fill='#D80027' />
    </svg>
  );
}

function Zambia(): JSX.Element {
  return (
    <svg viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M64.5 128.25C99.8462 128.25 128.5 99.5962 128.5 64.25C128.5 28.9038 99.8462 0.25 64.5 0.25C29.1538 0.25 0.5 28.9038 0.5 64.25C0.5 99.5962 29.1538 128.25 64.5 128.25Z' fill='#496E2D' />
      <path d='M89.5435 64.25H64.5V128.25C71.981 128.25 79.1603 126.962 85.8333 124.604L89.5435 64.25Z' fill='#D80027' />
      <path d='M107.167 111.948C120.259 100.23 128.5 83.2033 128.5 64.25H103.457L107.167 111.948Z' fill='#FF9811' />
      <path d='M85.833 64.25V124.604C93.7823 121.793 101.011 117.459 107.167 111.949V64.25H85.833Z' fill='black' />
      <path d='M118.761 41.9892H102.065C102.065 38.9157 99.5733 36.424 96.4998 36.424C93.4263 36.424 90.9345 38.9157 90.9345 41.9892H74.2388C74.2388 45.063 76.916 47.5545 79.9893 47.5545H79.804C79.804 50.6282 82.2953 53.1197 85.3693 53.1197C85.3693 56.1935 87.8605 58.685 90.9345 58.685H102.065C105.139 58.685 107.63 56.1935 107.63 53.1197C110.704 53.1197 113.196 50.6282 113.196 47.5545H113.01C116.084 47.5542 118.761 45.0627 118.761 41.9892Z' fill='#FF9811' />
    </svg>
  );
}

export const ISO_4217_CURRENCY_CODES = [
  { code: 'AED', description: 'Dirham de los Emiratos Árabes Unidos', symbol: 'Dh', icon: <UnitedArabEmirates /> },
  { code: 'ARS', description: 'Peso argentino', symbol: '$', icon: <Argentina /> },
  { code: 'AUD', description: 'Dólar australiano', symbol: '$', icon: <Australia /> },
  { code: 'BHD', description: 'Dinar bareiní', symbol: 'BD', icon: <Bahrain /> },
  { code: 'BDT', description: 'Taka bangladesí', symbol: '৳', icon: <Bangladesh /> },
  { code: 'BGN', description: 'Lev búlgaro', symbol: 'лв', icon: <Bulgaria /> },
  { code: 'BOB', description: 'Boliviano', symbol: 'Bs.', icon: <Bolivia /> },
  { code: 'BRL', description: 'Real brasileño', symbol: 'R$', icon: <Brazil /> },
  { code: 'BYN', description: 'Rublo bielorruso', symbol: 'Br', icon: <Belarus /> },
  { code: 'CAD', description: 'Dólar canadiense', symbol: '$', icon: <Canada /> },
  { code: 'CHF', description: 'Franco suizo', symbol: 'CHF', icon: <Switzerland /> },
  { code: 'CLP', description: 'Peso chileno', symbol: '$', icon: <Chile /> },
  { code: 'CNY', description: 'Yuan chino', symbol: '¥', icon: <China /> },
  { code: 'COP', description: 'Peso colombiano', symbol: '$', icon: <Colombia /> },
  { code: 'CRC', description: 'Colón costarricense', symbol: '₡', icon: <CostaRica /> },
  { code: 'CZK', description: 'Corona checa', symbol: 'Kč', icon: <CzechRepublic /> },
  { code: 'DZD', description: 'Dinar argelino', symbol: 'DA', icon: <Algeria /> },
  { code: 'DKK', description: 'Corona danesa', symbol: 'kr', icon: <Denmark /> },
  { code: 'EGP', description: 'Libra egipcia', symbol: 'E£', icon: <Egypt /> },
  { code: 'EUR', description: 'Euro', symbol: '€', icon: <EuropeanUnion /> },
  { code: 'FJD', description: 'Dólar fiyiano', symbol: '$', icon: <Fiji /> },
  { code: 'GBP', description: 'Libra esterlina', symbol: '£', icon: <UnitedKingdom /> },
  { code: 'GEL', description: 'Lari georgiano', symbol: '₾', icon: <Georgia /> },
  { code: 'GHS', description: 'Cedi ghanés', symbol: '₵', icon: <Ghana /> },
  { code: 'GNF', description: 'Franco guineano', symbol: 'FG', icon: <Guinea /> },
  { code: 'GTQ', description: 'Quetzal guatemalteco', symbol: 'Q', icon: <Guatemala /> },
  { code: 'HKD', description: 'Dólar de Hong Kong', symbol: '$', icon: <HongKong /> },
  { code: 'HRK', description: 'Kuna croata', symbol: 'kn', icon: <Croatia /> },
  { code: 'HUF', description: 'Florín húngaro', symbol: 'Ft', icon: <Hungary /> },
  { code: 'IDR', description: 'Rupia indonesia', symbol: 'Rp', icon: <Indonesia /> },
  { code: 'ILS', description: 'Nuevo séquel israelí', symbol: '₪', icon: <Israel /> },
  { code: 'INR', description: 'Rupia india', symbol: '₹', icon: <India /> },
  { code: 'IQD', description: 'Dinar iraquí', symbol: 'IQD', icon: <Iraq /> },
  { code: 'IRR', description: 'Rial iraní', symbol: 'IRR', icon: <Iran /> },
  { code: 'JOD', description: 'Dinar jordano', symbol: 'JD', icon: <Jordan /> },
  { code: 'JPY', description: 'Yen japonés', symbol: '¥', icon: <Japan /> },
  { code: 'KES', description: 'Chelín keniano', symbol: 'KSh', icon: <Kenya /> },
  { code: 'KGS', description: 'Som kirguís', symbol: 'с', icon: <Kyrgyzstan /> },
  { code: 'KRW', description: 'Won surcoreano', symbol: '₩', icon: <SouthKorea /> },
  { code: 'KWD', description: 'Dinar kuwaití', symbol: 'KD', icon: <Kuwait /> },
  { code: 'LBP', description: 'Libra libanesa', symbol: 'LL', icon: <Lebanon /> },
  { code: 'LKR', description: 'Rupia de Sri Lanka', symbol: 'Rs', icon: <SriLanka /> },
  { code: 'MAD', description: 'Dirham marroquí', symbol: 'Dh', icon: <Morocco /> },
  { code: 'MDL', description: 'Leu moldavo', symbol: 'L', icon: <Moldova /> },
  { code: 'MEX', description: 'Peso mexicano', symbol: '$', icon: <Mexico /> },  // TODO: Correct to MXN
  { code: 'MYR', description: 'Ringgit malayo', symbol: 'RM', icon: <Malaysia /> },
  { code: 'NGN', description: 'Naira nigeriano', symbol: '₦', icon: <Nigeria /> },
  { code: 'NIO', description: 'Córdoba nicaragüense', symbol: 'C$', icon: <Nicaragua /> },
  { code: 'NOK', description: 'Corona noruega', symbol: 'kr', icon: <Norway /> },
  { code: 'NZD', description: 'Dólar neozelandés', symbol: '$', icon: <NewZealand /> },
  { code: 'OMR', description: 'Rial omaní', symbol: 'OMR', icon: <Oman /> },
  { code: 'PEN', description: 'Sol peruano', symbol: 'S/', icon: <Peru /> },
  { code: 'PHP', description: 'Peso filipino', symbol: '₱', icon: <Philippines /> },
  { code: 'PLN', description: 'Zloty polaco', symbol: 'zł', icon: <Poland /> },
  { code: 'QAR', description: 'Riyal catarí', symbol: 'QR', icon: <Qatar /> },
  { code: 'RON', description: 'Leu rumano', symbol: 'lei', icon: <Romania /> },
  { code: 'RUB', description: 'Rublo ruso', symbol: '₽', icon: <Russia /> },
  { code: 'SAR', description: 'Riyal saudí', symbol: 'SAR', icon: <SaudiArabia /> },
  { code: 'SEK', description: 'Corona sueca', symbol: 'kr', icon: <Sweden /> },
  { code: 'SGD', description: 'Dólar de Singapur', symbol: '$', icon: <Singapore /> },
  { code: 'THB', description: 'Baht tailandés', symbol: '฿', icon: <Thailand /> },
  { code: 'TRY', description: 'Lira turca', symbol: '₺', icon: <Turkey /> },
  { code: 'TWD', description: 'Nuevo dólar taiwanés', symbol: 'NT$', icon: <Taiwan /> },
  { code: 'UAH', description: 'Grivna ucraniana', symbol: '₴', icon: <Ukraine /> },
  { code: 'USD', description: 'Dólar estadounidense', symbol: '$', icon: <UnitedStates /> },
  { code: 'UYU', description: 'Peso uruguayo', symbol: '$U', icon: <Uruguay /> },
  { code: 'VES', description: 'Bolívar venezolano', symbol: 'Bs.', icon: <Venezuela /> },
  { code: 'VND', description: 'Dong vietnamita', symbol: '₫', icon: <Vietnam /> },
  { code: 'XAF', description: 'Franco CFA de África Central', symbol: 'FCFA', icon: <CentralAfricanRepublic /> },
  { code: 'YER', description: 'Rial yemení', symbol: 'YER', icon: <Yemen /> },
  { code: 'ZAR', description: 'Rand sudafricano', symbol: 'R', icon: <SouthAfrica /> },
  { code: 'ZMW', description: 'Kwacha zambiano', symbol: 'ZK', icon: <Zambia /> },
];

export const DEFAULT_CURRENCY_CODE = 'USD';
