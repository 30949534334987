import { useContext, useState, useEffect, SyntheticEvent } from "react";
import { ISO_4217_CURRENCY_CODES } from "../../consts/currency-codes";
import { Context } from "../../utils/context";
import { processDecimalInput } from "../../utils/process-decimal-input";
import Loading from "../Loading";
import Select from "../Select";
import { FinancialElement } from "../../model/FinancialElement";
import { OperationDetail } from "../../model/OperationDetail";
import { Goal } from "../../model/Goal";

type GeneralItem = FinancialElement | OperationDetail | Goal;

interface ItemFormProps {
    title: string,
    initialItem: GeneralItem,
    onSubmit: (itemToSubmit: GeneralItem) => Promise<void>,
    onDelete?: (itemToDelete: GeneralItem) => Promise<void>,
    onCancel: () => void,
}

export default function ItemForm(props: ItemFormProps): JSX.Element {
    const { setConfirmDialog } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState(props.initialItem);

    useEffect(() => {
        setItem(props.initialItem);
    }, [props.initialItem]);

    if (loading) return <Loading />;

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setItem(oldItem => ({
            ...oldItem,
            [target.name]: target.value
        }));
    }

    async function handleDelete() {
        if (!props.onDelete) return;
        setLoading(true);
        await props.onDelete(item);
        props.onCancel();
        setLoading(false);
    }

    async function handleSubmit() {
        setLoading(true);
        await props.onSubmit(item);
        props.onCancel();
        setLoading(false);
    }

    const confirmDelete = {
        callback: handleDelete,
        title: '¿Estás seguro que deseas eliminar este elemento?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    const isDisabled = (item.title?.length ?? 0) < 3 || ('currency_code' in item && parseFloat(item.amount || '0') <= 0);

    return (
        <article className="h-fit bg-sf-violet-light">
            <h2>{props.title}</h2>
            <input name='title' type='text' placeholder='Nombre' value={item.title} onChange={handleInputChange} className='field' />
            {'currency_code' in item &&
                <div className='flex space-x-3'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency_code' value={item.currency_code} onChange={handleInputChange} options={ISO_4217_CURRENCY_CODES.map(currency => ({ value: currency.code, alias: currency.code }))} />
                    </div>
                    <input type='text' inputMode='decimal' name='amount' placeholder='Monto' value={item.amount} onChange={e => processDecimalInput(e) && handleInputChange(e)} className='field' />
                </div>
            }
            <div className='flex w-full justify-between items-center h-fit'>
                <button className='button-secondary' onClick={props.onCancel}>Cerrar</button>
                <div className='flex w-fit space-x-3'>
                    {props.onDelete && <button className='button-secondary-red' onClick={() => setConfirmDialog(confirmDelete)}>Eliminar</button>}
                    <button className='button-primary my-0' disabled={isDisabled} onClick={handleSubmit}>Guardar</button>
                </div>
            </div>
        </article>
    );
}
