import { useState, useEffect } from "react";
import { useVerticalScrollWithShadow } from "../../hooks/VerticalScrollWithShadow";
import { getInteger, getDecimal } from "../../utils/number-utils";
import EmptyList from "../EmptyList";
import ItemForm from "./ItemForm";
import { FinancialElement } from "../../model/FinancialElement";
import { Goal } from "../../model/Goal";
import { OperationDetail } from "../../model/OperationDetail";

type GeneralItem = FinancialElement | OperationDetail | Goal;

interface ProfileStepProps {
    title: string,
    items: GeneralItem[],
    getDefaultItem: () => GeneralItem,
    onCreate: (itemToCreate: GeneralItem) => Promise<void>,
    onUpdate: (itemToUpdate: GeneralItem) => Promise<void>,
    onDelete: (itemToDelete: GeneralItem) => Promise<void>,
}

export default function ProfileStep(props: ProfileStepProps): JSX.Element {
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const [newItem, setNewItem] = useState<GeneralItem>();
    const [itemToEdit, setItemToEdit] = useState<GeneralItem>();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        if (hidden) {
            setNewItem(undefined);
            setItemToEdit(undefined);
        }
    }, [hidden]);

    return (
        <article className="p-0 space-y-0 h-fit">
            <div className="p-3 flex flex-col space-y-3">
                <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-3">
                        <div className='flex items-center justify-center cursor-pointer hover:bg-sf-gray-extra-light rounded-lg w-6 h-6' onClick={() => setHidden(!hidden)}>
                            {hidden ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3.5" viewBox="0 0 14 8"><path fill="currentColor" d="M0.308162 0.305919C0.505537 0.110039 0.773199 0 1.05229 0C1.33138 0 1.59904 0.110039 1.79641 0.305919L7.00635 5.47795L12.2163 0.305919C12.4148 0.115591 12.6807 0.0102748 12.9566 0.0126554C13.2326 0.015036 13.4966 0.124922 13.6917 0.318647C13.8869 0.512371 13.9976 0.774433 14 1.04839C14.0024 1.32235 13.8963 1.58628 13.7045 1.78334L7.75048 7.69408C7.55311 7.88996 7.28544 8 7.00635 8C6.72727 8 6.4596 7.88996 6.26223 7.69408L0.308162 1.78334C0.110846 1.5874 0 1.32169 0 1.04463C0 0.767573 0.110846 0.501858 0.308162 0.305919Z" /></svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3.5" viewBox="183.868 136.755 14 8"><path fill="currentColor" d="M 184.176 144.449 C 184.374 144.645 184.641 144.755 184.92 144.755 C 185.2 144.755 185.467 144.645 185.665 144.449 L 190.875 139.277 L 196.084 144.449 C 196.283 144.64 196.549 144.745 196.825 144.742 C 197.101 144.74 197.365 144.63 197.56 144.436 C 197.755 144.243 197.866 143.981 197.868 143.707 C 197.871 143.433 197.764 143.169 197.573 142.972 L 191.619 137.061 C 191.421 136.865 191.154 136.755 190.875 136.755 C 190.595 136.755 190.328 136.865 190.13 137.061 L 184.176 142.972 C 183.979 143.168 183.868 143.433 183.868 143.711 C 183.868 143.988 183.979 144.253 184.176 144.449 Z" transform="matrix(1, 0, 0, 1, 0, 1.4210854715202004e-14)" /></svg>
                            }
                        </div>
                        <h2>{props.title}</h2>
                    </div>
                    <div hidden={hidden} className='cursor-pointer hover:bg-sf-gray-extra-light rounded-lg w-6' onClick={() => {
                        setNewItem(props.getDefaultItem());
                        setItemToEdit(undefined);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </div>
                </div>
                {newItem &&
                    <ItemForm
                        title='Crear'
                        initialItem={newItem}
                        onSubmit={props.onCreate}
                        onCancel={() => setNewItem(undefined)}
                    />
                }
                {itemToEdit &&
                    <ItemForm
                        title='Editar'
                        initialItem={itemToEdit}
                        onDelete={props.onDelete}
                        onSubmit={props.onUpdate}
                        onCancel={() => setItemToEdit(undefined)}
                    />
                }
            </div>
            <div hidden={hidden} onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {props.items.length > 0 ?
                        props.items.map((item, index) =>
                            <div key={index} className="flex justify-between items-center p-3 cursor-pointer" onClick={() => {
                                setNewItem(undefined);
                                setItemToEdit(item);
                            }}>
                                <h3>{item.title}</h3>
                                {'currency_code' in item &&
                                    <div className='flex'>
                                        <p className="mx-1">{item.currency_code}</p>
                                        <p>{getInteger(item.amount ?? '')}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(item.amount ?? '')}</p>
                                    </div>
                                }
                            </div>
                        )
                        :
                        <EmptyList message="No hay elementos." />
                    }
                </ul>
            </div>
        </article>
    );
}
