import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SharedLayout from './pages/SharedLayout';
import Home from './pages/Home';
import Error from './pages/Error';
import Management from './pages/Management';
import Clients from './pages/Clients';
import Operations from './pages/Operations';
import OperationFull from './pages/OperationFull';
import NewOperation from './pages/NewOperation';
import Assets from './pages/Assets';
import Debts from './pages/Debts';
import NewDebt from './pages/NewDebt';
import DebtFull from './pages/DebtFull';
import Goals from './pages/Goals';
import NewGoal from './pages/NewGoal';
import GoalFull from './pages/GoalFull';
import Profile from './pages/Profile';
import Budgets from './pages/Budgets';
import BudgetFull from './pages/BudgetFull';
import NewBudget from './pages/NewBudget';
import ExchangeRates from './pages/ExchangeRates';
import OperationDetailFull from './pages/OperationDetailFull';
import NewOperationDetail from './pages/NewOperationDetail';
import NewAsset from './pages/NewAsset';
import AssetFull from './pages/AssetFull';
import TransactionFull from './pages/TransactionFull';
import Transactions from './pages/Transactions';
import Plan from './pages/Plan';
import YearPlans from './pages/YearPlans';
import NewPlan from './pages/NewPlan';
import PlanFull from './pages/PlanFull';
import Cashflow from './pages/Cashflow';
import OpertaionsFiles from './pages/OperationsFiles';
import InitialProfile from './pages/InitialProfile';

export default function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<SharedLayout />}>

          <Route index element={<Home />} />

          <Route path='exchange_rates' element={<ExchangeRates />} />
          <Route path='management' element={<Management />} />
          <Route path='clients' element={<Clients />} />

          <Route path='initial_profile' element={<InitialProfile />} />

          <Route path='operations' element={<Operations />} />
          <Route path='operations/new' element={<NewOperation />} />
          <Route path='operations/files' element={<OpertaionsFiles />} />
          <Route path='operations/:operation_id' element={<OperationFull />} />

          <Route path='transactions' element={<Transactions />} />
          <Route path='transactions/:grouping_id' element={<TransactionFull />} />

          <Route path='budgets' element={<Budgets />} />
          <Route path='budgets/new' element={<NewBudget />} />
          <Route path='budgets/:timestamp_to' element={<BudgetFull />} />

          <Route path='plan' element={<Plan />} />
          <Route path='plan/new' element={<NewPlan />} />
          <Route path='plan/:timestamp_to' element={<PlanFull />} />

          <Route path='cashflow' element={<Cashflow />} />

          <Route path='yearplans' element={<YearPlans />} />

          <Route path='assets' element={<Assets />} />
          <Route path='assets/new' element={<NewAsset />} />
          <Route path='assets/:asset_id' element={<AssetFull />} />

          <Route path='debts' element={<Debts />} />
          <Route path='debts/new' element={<NewDebt />} />
          <Route path='debts/:debt_id' element={<DebtFull />} />

          <Route path='goals' element={<Goals />} />
          <Route path='goals/new' element={<NewGoal />} />
          <Route path='goals/:goal_id' element={<GoalFull />} />

          <Route path='profile' element={<Profile />} />
          <Route path='operation_details/new' element={<NewOperationDetail />} />
          <Route path='operation_details/:operation_detail_id' element={<OperationDetailFull />} />

          <Route path='*' element={<Error />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}
