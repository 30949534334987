import React, { useContext, useState, useMemo } from "react";
import { FinancialElementTypes } from "../../consts/FinancialElementTypes";
import { OperationTypes } from "../../consts/OperationTypes";
import { useVerticalScrollWithShadow } from "../../hooks/VerticalScrollWithShadow";
import { Context } from "../../utils/context";
import { getInteger, getDecimal } from "../../utils/number-utils";
import EmptyList from "../EmptyList";
import { STRESS_LEVELS } from "../../consts/stress-levels";

interface InitialProfileSummaryProps {
    close: () => void;
}

export default function InitialProfileSummary(props: InitialProfileSummaryProps): JSX.Element {
    const { client, goals, operationDetails, financialElements } = useContext(Context);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const categoryConfigs = useMemo(() => {
        const wasCreatedDuringInitialProfile = (item: { creation_timestamp?: string }) => parseInt(item.creation_timestamp || '0') < parseInt(client.initial_profile_leave_timestamp || '0');

        return [
            {
                label: 'Objetivos',
                items: goals.filter(wasCreatedDuringInitialProfile),
            },
            {
                label: 'Ingresos',
                items: operationDetails.filter(wasCreatedDuringInitialProfile)
                    .filter(operationDetail => operationDetail.type === OperationTypes.INCOME.key),
            },
            {
                label: 'Egresos',
                items: operationDetails.filter(wasCreatedDuringInitialProfile)
                    .filter(operationDetail => operationDetail.type === OperationTypes.EXPENDITURE.key),
            },
            {
                label: 'Deudas',
                items: financialElements.filter(wasCreatedDuringInitialProfile)
                    .filter(financialElement => financialElement.type === FinancialElementTypes.DEBT.key),
            },
            {
                label: 'Ahorros',
                items: financialElements.filter(wasCreatedDuringInitialProfile)
                    .filter(financialElement => financialElement.type === FinancialElementTypes.ASSET.key),
            },
        ];
    }, [client, goals, operationDetails, financialElements]);
    const [selectedCategory, setSelectedCategory] = useState(categoryConfigs[0]?.label ?? '');

    const itemsToDisplay = categoryConfigs.find(category => category.label === selectedCategory)?.items ?? [];

    return (
        <article className="z-60 p-0 space-y-0 h-fit shadow-lg w-full min-w-[40%] lg:w-fit" onClick={event => event.stopPropagation()}>
            <div className="flex w-full flex-row justify-between items-center p-3">
                <h2>Datos del Perfil inicial</h2>
                <div className="h-8 w-8 p-1 rounded-lg hover:bg-sf-gray-extra-light flex items-center justify-center cursor-pointer" onClick={props.close}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 24 24">
                        <path fill="currentColor" d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z" />
                    </svg>
                </div>
            </div>
            <div className="flex px-3 items-center space-x-3">
                <h3>Stress financiero inicial:</h3>
                <div className='w-6 h-6'>
                    {STRESS_LEVELS.find(stressLevel => client.stress_level === stressLevel.key)?.icon}
                </div>
            </div>
            <div className="field p-3 flex-wrap space-x-1.5">
                {categoryConfigs.map((categoryConfig, index) =>
                    <div key={index} className="flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1" onClick={() => setSelectedCategory(categoryConfig.label)} >
                        <div className={`rounded-full h-3.5 w-3.5 ${categoryConfig.label === selectedCategory ? "bg-sf-violet-dark" : "ring-1 ring-inset ring-sf-violet-dark"}`} />
                        <div>{categoryConfig.label}</div>
                    </div>
                )}
            </div>
            <div onScroll={onScrollHandler} ref={ref} className={`px-3 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {itemsToDisplay.length > 0 ? itemsToDisplay.map((item, index) =>
                        <li key={index} className="flex justify-between items-center p-3">
                            <h3>{item.title}</h3>
                            {"currency_code" in item &&
                                <div className='flex'>
                                    <p className="mx-1">{item.currency_code}</p>
                                    <p>{getInteger(item.amount || '0')}</p>
                                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(item.amount || '0')}</p>
                                </div>
                            }
                        </li>
                    ) :
                        <EmptyList message="No hay elementos." />
                    }
                </ul>
            </div>
        </article>
    );
}
