import { useContext, Dispatch, SetStateAction } from "react";
import ClientAppointment from "../components/initial-profile/ClientAppointment";
import FinancialStressStep from "../components/initial-profile/FinancialStressStep";
import LeaveInitialProfile from "../components/initial-profile/LeaveInitialProfile";
import ProfileStep from "../components/initial-profile/ProfileStep";
import { DEFAULT_CURRENCY_CODE } from "../consts/currency-codes";
import { FinancialElementTypes } from "../consts/FinancialElementTypes";
import { OperationTypes } from "../consts/OperationTypes";
import { FinancialElement, defaultFinancialElement } from "../model/FinancialElement";
import { Goal, defaultGoal } from "../model/Goal";
import { OperationDetail, defaultOperationDetail } from "../model/OperationDetail";
import { updateClient, createGoal, updateGoal, deleteGoal, createOperationDetail, updateOperationDetail, deleteOperationDetail, createFinancialElement, updateFinancialElement, deleteFinancialElement } from "../utils/api";
import { Context } from "../utils/context";
import ClientData from "../components/initial-profile/ClientData";

type GeneralItem = FinancialElement | OperationDetail | Goal;

export default function InitialProfile(): JSX.Element {
    const { client, setClient, operationDetails, setOperationDetails, goals, setGoals, financialElements, setFinancialElements } = useContext(Context);

    const defaultCurrencyCode = client.currencies ? client.currencies[0]?.code ?? DEFAULT_CURRENCY_CODE : DEFAULT_CURRENCY_CODE;

    async function updateDefaultCurrencyCode(currencyCode: string) {
        const newCurrencies = [...client.currencies ?? []];
        newCurrencies.unshift({
            code: currencyCode
        });
        const uniqueCurrencies = newCurrencies.filter((currency, index, self) => index === self.findIndex(c => c.code === currency.code));
        const updatedClient = {
            ...client,
            currencies: uniqueCurrencies
        };
        await updateClient(client.id ?? '', updatedClient);
        setClient(updatedClient);
    }

    async function createItem(itemToCreate: GeneralItem, setItems: Dispatch<SetStateAction<GeneralItem[]>>) {
        if ('currency_code' in itemToCreate && itemToCreate.currency_code && itemToCreate.currency_code !== defaultCurrencyCode) {
            await updateDefaultCurrencyCode(itemToCreate.currency_code);
        };
        setItems(oldItems => [...oldItems, itemToCreate]);
    }

    async function updateItem(itemToUpdate: GeneralItem, setItems: Dispatch<SetStateAction<GeneralItem[]>>) {
        setItems(oldItems => {
            const newItems = [...oldItems];
            const index = newItems.findIndex(item => item.order_key === itemToUpdate.order_key);
            if (index !== -1) {
                newItems[index] = itemToUpdate;
            }
            return newItems;
        });
    }

    async function deleteItem(itemToDelete: GeneralItem, setItems: Dispatch<SetStateAction<GeneralItem[]>>) {
        setItems(oldItems => oldItems.filter(item => item.order_key !== itemToDelete.order_key));
    }

    return (
        <main>
            <h1 className='text-2xl'>Perfil inicial</h1>
            <div className="space-y-3 lg:space-y-8">
                <ProfileStep
                    title='Objetivos'
                    items={goals}
                    getDefaultItem={() => ({
                        ...defaultGoal,
                        status: 'pending',
                        order_key: new Date().getTime().toString()
                    })}
                    onCreate={async (goalToCreate: Goal) => {
                        const createdGoal = await createGoal(client.id ?? '', goalToCreate);
                        await createItem(createdGoal, setGoals);
                    }}
                    onUpdate={async (goalToUpdate: Goal) => {
                        await updateGoal(client.id ?? '', goalToUpdate.goal_id ?? '', goalToUpdate);
                        await updateItem(goalToUpdate, setGoals);
                    }}
                    onDelete={async (goalToDelete: Goal) => {
                        await deleteGoal(client.id ?? '', goalToDelete.goal_id ?? '');
                        await deleteItem(goalToDelete, setGoals);
                    }}
                />
                <ProfileStep
                    title='Ingresos'
                    items={operationDetails.filter(operationDetail => operationDetail.type === OperationTypes.INCOME.key)}
                    getDefaultItem={() => ({
                        ...defaultOperationDetail,
                        currency_code: defaultCurrencyCode,
                        type: OperationTypes.INCOME.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    onCreate={async (incomeItemToCreate: OperationDetail) => {
                        const createdIncomeItem = await createOperationDetail(client.id ?? '', incomeItemToCreate);
                        await createItem(createdIncomeItem, setOperationDetails);
                    }}
                    onUpdate={async (incomeItemToUpdate: OperationDetail) => {
                        await updateOperationDetail(client.id ?? '', incomeItemToUpdate.id ?? '', incomeItemToUpdate);
                        await updateItem(incomeItemToUpdate, setOperationDetails);
                    }}
                    onDelete={async (incomeItemToDelete: OperationDetail) => {
                        await deleteOperationDetail(client.id ?? '', incomeItemToDelete.id ?? '');
                        await deleteItem(incomeItemToDelete, setOperationDetails);
                    }}
                />
                <ProfileStep
                    title='Egresos'
                    items={operationDetails.filter(operationDetail => operationDetail.type === OperationTypes.EXPENDITURE.key).map(operationDetail => ({ ...operationDetail, amount: operationDetail.amount?.replace('-', '') }))}
                    getDefaultItem={() => ({
                        ...defaultOperationDetail,
                        currency_code: defaultCurrencyCode,
                        type: OperationTypes.EXPENDITURE.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    onCreate={async (expenseToCreate: OperationDetail) => {
                        const createdExpense = await createOperationDetail(client.id ?? '', {
                            ...expenseToCreate,
                            amount: `-${expenseToCreate.amount}`
                        });
                        await createItem(createdExpense, setOperationDetails);
                    }}
                    onUpdate={async (expenseToUpdate: OperationDetail) => {
                        await updateOperationDetail(client.id ?? '', expenseToUpdate.id ?? '', {
                            ...expenseToUpdate,
                            amount: `-${expenseToUpdate.amount}`
                        });
                        await updateItem(expenseToUpdate, setOperationDetails);
                    }}
                    onDelete={async (expenseToDelete: OperationDetail) => {
                        await deleteOperationDetail(client.id ?? '', expenseToDelete.id ?? '');
                        await deleteItem(expenseToDelete, setOperationDetails);
                    }}
                />
                <ProfileStep
                    title='Deudas'
                    items={financialElements.filter(financialElement => financialElement.type === FinancialElementTypes.DEBT.key).map(operationDetail => ({ ...operationDetail, amount: operationDetail.amount?.replace('-', '') }))}
                    getDefaultItem={() => ({
                        ...defaultFinancialElement,
                        currency_code: defaultCurrencyCode,
                        type: FinancialElementTypes.DEBT.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    onCreate={async (debtToCreate: FinancialElement) => {
                        const createdDebt = await createFinancialElement(client.id ?? '', {
                            ...debtToCreate,
                            amount: `-${debtToCreate.amount}`
                        });
                        await createItem(createdDebt, setFinancialElements);
                    }}
                    onUpdate={async (debtToUpdate: FinancialElement) => {
                        await updateFinancialElement(client.id ?? '', debtToUpdate.id ?? '', {
                            ...debtToUpdate,
                            amount: `-${debtToUpdate.amount}`
                        });
                        await updateItem(debtToUpdate, setFinancialElements);
                    }}
                    onDelete={async (debtToDelete: FinancialElement) => {
                        await deleteFinancialElement(client.id ?? '', debtToDelete.id ?? '');
                        await deleteItem(debtToDelete, setFinancialElements);
                    }}
                />
                <ProfileStep
                    title='Ahorros'
                    items={financialElements.filter(financialElement => financialElement.type === FinancialElementTypes.ASSET.key)}
                    getDefaultItem={() => ({
                        ...defaultFinancialElement,
                        currency_code: defaultCurrencyCode,
                        type: FinancialElementTypes.ASSET.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    onCreate={async (savingToCreate: FinancialElement) => {
                        const createdSaving = await createFinancialElement(client.id ?? '', savingToCreate);
                        await createItem(createdSaving, setFinancialElements);
                    }}
                    onUpdate={async (savingToUpdate: FinancialElement) => {
                        await updateFinancialElement(client.id ?? '', savingToUpdate.id ?? '', savingToUpdate);
                        await updateItem(savingToUpdate, setFinancialElements);
                    }}
                    onDelete={async (savingToDelete: FinancialElement) => {
                        await deleteFinancialElement(client.id ?? '', savingToDelete.id ?? '');
                        await deleteItem(savingToDelete, setFinancialElements);
                    }}
                />
                <FinancialStressStep />
                <ClientData />
                <ClientAppointment />
                <LeaveInitialProfile />
            </div>
        </main>
    );
}
