export interface Goal {
    readonly 'amount'?: string;
    readonly 'title'?: string;
    readonly 'status'?: string;
    readonly 'order_key'?: string;
    readonly 'term'?: string;
    readonly 'currency'?: string;
    readonly 'description'?: string;
    readonly 'goal_id'?: string;
    readonly 'creation_timestamp'?: string;
    readonly 'update_timestamp'?: string;
};

export const defaultGoal: Goal = {
    'amount': '',
    'title': '',
    'status': '',
    'order_key': '',
    'term': '',
    'currency': '',
    'description': '',
    'goal_id': '',
    'update_timestamp': '',
};
