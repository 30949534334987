import { createExchangeRate, deleteExchangeRate, listExchangeRates, updateExchangeRate } from '../utils/api';
import { ExchangeRate } from '../model/ExchangeRate';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { ISO_4217_CURRENCY_CODES } from "../consts/currency-codes";
import { Context } from '../utils/context';
import { processDecimalInput } from '../utils/process-decimal-input';
import Select from '../components/Select';
import { getFormattedLocaleDate, ifDateIsValidThen } from '../utils/date-utils';

function subtractOneMonthToTimestamp(date: string): string {
    // Suppose the provided string is the timestamp of 2023-10-01T00:00:00.000Z (which is 1696118400000)

    // Parse provided date.
    // 1696118400000 -> Sat Sep 30 2023 21:00:00 GMT-0300 (Argentina Standard Time)
    const parseDate = new Date(parseInt(date));

    // Trick the date to have the correct date at midnight, no matter the timezone.
    // Sat Sep 30 2023 21:00:00 GMT-0300 (Argentina Standard Time) -> Sun Oct 01 2023 00:00:00 GMT-0300 (Argentina Standard Time)
    const shiftedDate = new Date(parseDate.toUTCString().slice(0, -4));

    // Go to next month.
    // Sun Oct 01 2023 00:00:00 GMT-0300 (Argentina Standard Time) -> Wed Nov 01 2023 00:00:00 GMT-0300 (Argentina Standard Time)
    shiftedDate.setMonth(shiftedDate.getMonth() - 1);

    // Apply the inverse trick to restore the original hour and timezone.
    // Wed Nov 01 2023 00:00:00 GMT-0300 (Argentina Standard Time) -> Wed Nov 01 2023 00:00:00 GMT
    const recomposeDateTimestamp = Date.UTC(shiftedDate.getFullYear(), shiftedDate.getMonth(), shiftedDate.getDate());

    // console.log('Input date: ' + new Date(parseInt(date)).toISOString());
    // console.log('Output date: ' + new Date(recomposeDateTimestamp).toISOString());

    // Convert to string and return.
    // Wed Nov 01 2023 00:00:00 GMT -> 1698807600000 (this is the timestamp of 2023-11-01T00:00:00.000Z)
    return recomposeDateTimestamp.toString();
}

interface NewExchangeRateProps {
    exchangeRate: ExchangeRate
    handleCreate: (item: ExchangeRate) => void
}
function NewExchangeRate(props: NewExchangeRateProps): JSX.Element {
    const [newRate, setNewRate] = useState('');
    const exchangeRate = props.exchangeRate;
    const handleCreate = props.handleCreate;

    return (
        <div className='flex justify-between items-center ring-1 ring-sf-violet-dark bg-sf-white rounded-lg w-full h-fit px-3 py-2'>
            <div className='w-1/4'>{new Date(parseInt(exchangeRate?.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</div>
            <div className='w-1/2 flex items-center rounded-lg space-x-1 bg-sf-violet-dark px-4 h-10 text-sf-white justify-center'>
                <div>1</div>
                <div>USD</div>
                <div className='text-3xl pl-1'>⇄</div>
                <div className='font-bold w-24 flex justify-end'>
                    <input type='text' inputMode='decimal' name='rate' value={newRate} onChange={e => processDecimalInput(e) && setNewRate(e.target.value)} className='field mx-1 py-1 h-min text-sf-black text-end' />
                </div>
                <div>{exchangeRate.code}</div>
            </div>
            <div className="flex h-fit space-x-4 w-1/4 justify-center">
                {newRate !== '' ?
                    <button className="cursor-pointer" onClick={() => { handleCreate({ ...exchangeRate, rate: newRate }) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-green-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="M15 12h-3m0 0H9m3 0V9m0 3v3" /></g></svg>
                    </button>
                    :
                    <button className="cursor-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-gray-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="M15 12h-3m0 0H9m3 0V9m0 3v3" /></g></svg>
                    </button>
                }
            </div>
        </div>
    )
}

interface ExchangeRateItemProps {
    exchangeRate: ExchangeRate
    handleUpdate: (item: ExchangeRate) => void
    handleDelete: (item: ExchangeRate) => void
}
function ExchangeRateItem(props: ExchangeRateItemProps): JSX.Element {
    const [editAmount, setEditAmount] = useState(false);
    const [newRate, setNewRate] = useState(props.exchangeRate.rate);
    const exchangeRate = props.exchangeRate;
    const handleUpdate = props.handleUpdate;
    const handleDelete = props.handleDelete;

    return (
        <div className='flex justify-between items-center ring-1 ring-sf-violet-dark bg-sf-white rounded-lg w-full h-fit px-3 py-2'>
            <div className='w-1/4 '>{new Date(parseInt(exchangeRate?.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</div>
            <div className='w-1/2 flex items-center rounded-lg space-x-1 bg-sf-violet-dark px-4 h-10 text-sf-white justify-center'>
                <div>1</div>
                <div>USD</div>
                <div className='text-3xl pl-1'>⇄</div>
                <div className='font-bold w-24 flex justify-end'>
                    {editAmount ?
                        <input type='text' inputMode='decimal' name='rate' value={newRate} onChange={e => processDecimalInput(e) && setNewRate(e.target.value)} className='field mx-1 py-1 h-min text-sf-black text-end' />
                        :
                        <div className='w-full p-3 text-sm ring-1 mx-1 py-1 h-min rounded-lg ring-sf-white ring-inset text-end'>{exchangeRate.rate}</div>
                    }
                </div>
                <div>{exchangeRate.code}</div>
            </div>

            {editAmount ?
                <div className="flex h-fit space-x-6 w-1/4 justify-center">
                    <button className="cursor-pointer" onClick={() => { handleUpdate({ ...exchangeRate, rate: newRate }); setEditAmount(oldEditAmount => !oldEditAmount); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" strokeLinejoin="round" d="m8.5 12.5l2 2l5-5" /></g></svg>
                    </button>
                    <button className="cursor-pointer" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-red-dark" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10" /><path strokeLinecap="round" d="m14.5 9.5l-5 5m0-5l5 5" /></g></svg>
                    </button>
                </div>
                :
                <div className="flex h-fit space-x-6 w-1/4 justify-center">
                    <button className="cursor-pointer" onClick={() => setEditAmount(oldEditAmount => !oldEditAmount)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-violet-dark" viewBox="0 0 24 24"><g fill="currentColor"><path d="M20.849 8.713a3.932 3.932 0 0 0-5.562-5.561l-.887.887l.038.111a8.754 8.754 0 0 0 2.093 3.32a8.754 8.754 0 0 0 3.43 2.13l.888-.887Z" opacity=".5" /><path d="m14.439 4l-.039.038l.038.112a8.754 8.754 0 0 0 2.093 3.32a8.753 8.753 0 0 0 3.43 2.13l-8.56 8.56c-.578.577-.867.866-1.185 1.114a6.554 6.554 0 0 1-1.211.748c-.364.174-.751.303-1.526.561l-4.083 1.361a1.06 1.06 0 0 1-1.342-1.341l1.362-4.084c.258-.774.387-1.161.56-1.525c.205-.43.456-.836.749-1.212c.248-.318.537-.606 1.114-1.183L14.439 4Z" /></g></svg>
                    </button>
                    <button className="cursor-pointer" onClick={() => { handleDelete(exchangeRate); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 text-sf-black" viewBox="0 0 24 24"><g fill="currentColor"><path d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877Z" /><path fillRule="evenodd" d="M9.425 11.482c.413-.044.78.273.821.707l.5 5.263c.041.433-.26.82-.671.864c-.412.043-.78-.273-.821-.707l-.5-5.263c-.041-.434.26-.821.671-.864Zm5.15 0c.412.043.713.43.671.864l-.5 5.263c-.04.434-.408.75-.82.707c-.413-.044-.713-.43-.672-.864l.5-5.264c.041-.433.409-.75.82-.707Z" clipRule="evenodd" /><path d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886Z" opacity=".5" /></g></svg>
                    </button>
                </div>
            }
        </div>
    )
}

export default function ExchangeRates(): JSX.Element {
    const { setConfirmDialog } = useContext(Context);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [queryParams, setQueryParams] = useState({ from: '', to: '', codes: [''] });

    useEffect(() => {
        const today = new Date();
        setQueryParams({
            from: getFormattedLocaleDate(new Date(parseInt(subtractOneMonthToTimestamp(today.getTime().toString())))),
            to: getFormattedLocaleDate(today),
            codes: ['EUR']
        });
    }, []);

    function handleQueryParamsChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setQueryParams(oldQueryParams => ({
            ...oldQueryParams,
            [target.name]: target.value
        }));
    }

    function handleListExchangeRate() {
        setExchangeRates([]);
        const fromTime = new Date(queryParams.from ?? '').getTime();
        const toTime = new Date(queryParams.to ?? '').getTime();
        listExchangeRates(queryParams.codes, {
            from: fromTime.toString(),
            to: toTime.toString(),
        }).then(exchangeRates => {
            return exchangeRates.filter(exchangeRate => ((exchangeRate.timestamp ?? '') >= fromTime.toString()) && ((exchangeRate.timestamp ?? '') <= toTime.toString()));
        }).then(exchangeRates => {
            for (let date = fromTime; date <= toTime; date += 86400000) {
                if (exchangeRates.findIndex(exchangeRate => exchangeRate.timestamp === date.toString()) === -1) {
                    exchangeRates.push({ code: queryParams.codes[0], timestamp: date.toString() } as ExchangeRate);
                }
            }

            return exchangeRates;
        }).then(exchangeRates => {
            return exchangeRates.sort((a, b) => {
                if (a.timestamp === undefined || b.timestamp === undefined) return 0;

                if (a.timestamp > b.timestamp) return 1;
                if (a.timestamp < b.timestamp) return -1;

                return 0;
            });
        }).then(setExchangeRates);
    }

    function handleCurrencySelect(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;

        setQueryParams(oldQueryParams => ({ ...oldQueryParams, codes: [target.value] }));
    }

    function handleCreate(exchangeRate: ExchangeRate): void {
        createExchangeRate(exchangeRate).then(() => {
            setExchangeRates(oldExchangeRates =>
                oldExchangeRates.map(oldExchangeRate => oldExchangeRate.code === exchangeRate.code && oldExchangeRate.timestamp === exchangeRate.timestamp ? exchangeRate : oldExchangeRate)
            );
        });
    }

    function handleUpdate(exchangeRate: ExchangeRate): void {
        setExchangeRates(oldExchangeRates =>
            oldExchangeRates.map(oldExchangeRate => oldExchangeRate.code === exchangeRate.code && oldExchangeRate.timestamp === exchangeRate.timestamp ? { ...oldExchangeRate, rate: '...' } : oldExchangeRate)
        );
        updateExchangeRate(exchangeRate.code ?? '', exchangeRate.timestamp ?? '', exchangeRate).then(() => {
            setExchangeRates(oldExchangeRates =>
                oldExchangeRates.map(oldExchangeRate => oldExchangeRate.code === exchangeRate.code && oldExchangeRate.timestamp === exchangeRate.timestamp ? exchangeRate : oldExchangeRate)
            );
        });
    }

    function handleDelete(exchangeRate: ExchangeRate): Promise<void> {
        return deleteExchangeRate(exchangeRate.code ?? '', exchangeRate.timestamp ?? '').then(() => {
            setExchangeRates(oldExchangeRates =>
                oldExchangeRates.map(oldExchangeRate => oldExchangeRate.code === exchangeRate.code && oldExchangeRate.timestamp === exchangeRate.timestamp ? { ...oldExchangeRate, rate: undefined } : oldExchangeRate)
            );
        });
    }

    return (
        <main>
            <article className='w-fit bg-sf-violet-light'>
                <h2>Tipos de cambio</h2>
                <div className='flex space-x-4'>
                    <div className='w-48'>
                        <Select placeholder='Moneda' name='currency' value={queryParams.codes[0]} onChange={handleCurrencySelect} options={ISO_4217_CURRENCY_CODES.map(currencyCode => currencyCode.code)} />
                    </div>
                    <input name='from' type='date' value={queryParams.from} onChange={ifDateIsValidThen(handleQueryParamsChange)} className='field max-w-64' />
                    <input name='to' type='date' value={queryParams.to} onChange={ifDateIsValidThen(handleQueryParamsChange)} className='field max-w-64' />
                </div>
                <div className='flex justify-end'>
                    <button className='button-primary' onClick={handleListExchangeRate}>Obtener</button>
                </div>
                {exchangeRates.map((exchangeRate, index) => exchangeRate.rate === undefined ?
                    <NewExchangeRate exchangeRate={exchangeRate} key={index} handleCreate={handleCreate} />
                    :
                    <ExchangeRateItem exchangeRate={exchangeRate} key={index} handleUpdate={handleUpdate} handleDelete={exchangeRateToDelete => setConfirmDialog({
                        callback: () => handleDelete(exchangeRateToDelete),
                        title: '¿Estás seguro que deseas eliminar este tipo de cambio?',
                        confirmButton: 'Eliminar',
                        cancelButton: 'Cancelar'
                    })} />)}
            </article>
        </main >
    );
}
