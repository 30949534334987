import { useContext } from "react";
import { STRESS_LEVELS } from "../../consts/stress-levels";
import { Context } from "../../utils/context";

export default function FinancialStressStep(): JSX.Element {
    const { client } = useContext(Context);

    return (
        <article>
            <div className="flex justify-between items-center">
                <h2 className="min-w-fit">Stress financiero</h2>
                <div className="flex justify-evenly items-center w-full">
                    {STRESS_LEVELS.map((stressLevel, index) =>
                        <div key={index} className={`flex justify-center  items-center w-7 h-7 rounded-lg ${client.stress_level === stressLevel.key ? 'ring-1 ring-inset ring-sf-gray-medium bg-sf-gray-light' : 'bg-sf-white'}`}>
                            <div className="w-6 h-6">
                                {stressLevel.icon}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </article>
    );
}
